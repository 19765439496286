export const AdditionalOptionsTextBox = ({value, placeholder, callback}:{value: string | number | undefined, placeholder: string, callback: (value: string|undefined) => void}) => {
  return (<div className="w-full">
    <input className="w-full border border-lightest shadow placeholder-gray-200 text-sm p-2" placeholder={placeholder} value={value} defaultValue={value} onChange={(ev) => {
      const newValue = ev.target.value;
      if (newValue === "") {
        callback(undefined);
      } else {
        callback(newValue);
      }
    }} />
  </div>);
};
