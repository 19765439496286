import { Outlet } from "react-router-dom";

import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";
import { ReactComponent as ScraperAPIIllustration } from "assets/images/scraperapi-illustration.svg";

export default function BeforeLoginLayout() {
  return (
    <div className="z-10 flex flex-col md:flex-row w-full min-h-full">
      <div className="flex-1">
        <div className="relative flex items-center justify-center w-full h-full px-4 md:px-8">
          <div className="absolute top-4 left-4 w-44">
            <ScraperAPILogo />
          </div>
          <div className="w-full max-w-[500px] mt-24 mb-20 md:my-20">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="relative flex-1">
        <div className="flex flex-col items-center justify-center w-full h-full bg-brandLightest">
          <ScraperAPIIllustration className="w-3/5 my-16 max-w-[640px]" />
          <div className="flex flex-col text-2xl lg:text-3xl text-center mb-16 mx-10">
            <span>Web Scraping is complex.</span>
            <span>We make it simple.</span>
          </div>
        </div>
      </div>
    </div>
  );
};
