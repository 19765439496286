import { DetailedHTMLProps, LabelHTMLAttributes } from "react";

import { cx } from "utils";


export default function InputFieldLabel(
  {
    children,
    className,
    ...props
  } : DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
) {
  return (
    <>
      { children && (
        <label
          className={ cx(
            "block mb-1 text-sm text-gray select-none",
            className
          ) }
          { ...props }
        >
          { children }
        </label>
      ) }
    </>
  );
};
