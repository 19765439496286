import type { TypeSelectedCallback } from "./typeSelectedCallback";
import { projectTypeToTitleAndDescription, ProjectVariant } from "sdecontent";
import { projectIcon } from "components/hosted-scraping/ProjectIcon";
import { CollectorType } from 'providers/HostedScrapingProvider';

export const StructuredDatasourceCard = ({variant, configType, callback}:{variant: ProjectVariant, key: string, configType: CollectorType, callback: TypeSelectedCallback}) => {
  const {title, description, icon} = projectTypeToTitleAndDescription(variant, configType);
  const Icon = projectIcon(icon);
  return (
  <div 
    className="w-72 h-48 border border-slate-200 p-2 hover:bg-slate-50 cursor-pointer"
    onClick={() => callback(configType)}
  > 
    <div className="w-10 h-10 p-2 border border-slate-200 flex items-center justify-center">
      <Icon/>
    </div>
    <div className="text-base text-black font-semibold mt-2 mb-2">
      {title}
    </div>
    <div className="text-sm text-gray">
      {description}
    </div>

  </div>);
}
