import { Combobox } from "@headlessui/react";
import { ForwardedRef, forwardRef, useMemo, useState } from "react";

import { RiArrowDropDownLine } from "@remixicon/react";

import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";

import { cx } from "utils";


interface IComboBoxWithValidationProps<T> extends IInputFieldWithValidationProps {
  options: T[];
  inputDisplayValue?: (item: T) => string;
  listValue?: (item: T) => string;
}


const ComboBoxWithValidation = forwardRef((props: IComboBoxWithValidationProps<any>, ref: ForwardedRef<any>) => {
  const [ selection, setSelection ] = useState(props.value);
  const [ query, setQuery ] = useState((props.inputDisplayValue ? props.inputDisplayValue(props.value) : props.value as string) || "");

  const filteredOptions = useMemo(() => {
    return query === "" ?
      props.options :
      props.options.filter(option => (props.inputDisplayValue ? props.inputDisplayValue(option) : option).toString().toLowerCase().includes(query.toLowerCase()))
  }, [ query, props ]);


  return (
    <Combobox
      name={ props.name }
      value={ selection }
      onChange={ setSelection }
      ref={ ref }
    >
      { ({ open }) => (
        <>
          <div className="relative">
            <div className="relative">
              <Combobox.Input
                as={ InputFieldWithValidation }
                displayValue={ props.inputDisplayValue }
                required={ props.required }
                isValidated={ props.isValidated }
                label={ props.label }
                value={ props.inputDisplayValue ? props.inputDisplayValue(selection) : selection }
                onChange={ (event: any) => setQuery(event.target.value) }
                onFocusLost={ (event: any, setTrackedValue: (value: string | number | string[]) => void) => {
                  setTrackedValue(props.inputDisplayValue ? props.inputDisplayValue(selection) : (selection as string));
                } }
              />
              <Combobox.Button className="absolute inset-y-0 right-0 mt-1">
                <RiArrowDropDownLine className={ cx("w-5 h-5 transition-transform", open && "rotate-180") } />
              </Combobox.Button>
            </div>
            <Combobox.Options className="absolute w-full overflow-y-auto max-h-40 z-40 bg-white ring-1 p-2 gap-y-2">
              { filteredOptions.length === 0 ? (
                <Combobox.Option
                  value={ null }
                  className="text-sm"
                  disabled
                >
                  <div className="w-full italic">No such country</div>
                </Combobox.Option>
                ) :
                (
                  <>
                    { filteredOptions.map((option) => (
                      <Combobox.Option
                        value={ option }
                        key={ props.listValue?.(option) }
                        className="text-sm"
                      >
                        { ({ selected, active }) => (
                          <div className={ cx(
                            "w-full",
                            selected && "text-brandPrimary font-bold",
                            active && "bg-brandPrimary text-white")
                          }>
                            { props.listValue?.(option) || option }
                          </div>
                        ) }
                      </Combobox.Option>
                    )) }
                  </>
                )
              }
            </Combobox.Options>
          </div>
        </>
      ) }
    </Combobox>
  );
});

export default ComboBoxWithValidation;
