import { useCallback, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { ApiError } from "api";

import Form from "components/Form";
import { ErrorMsg, PasswordField } from "components/InputField";
import Button from "components/Button";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";

import { useUser } from "routes/dataroutes/UserData";


interface PasswordChangeFormProps {
  askOldPassword?: boolean;
  buttonText: string;
  cancelButtonAction?: () => void;
  changePasswordCallback: (oldPassword: string, newPassword: string) => Promise<any>;
}

export default function PasswordChangeForm(
  {
    askOldPassword = true,
    buttonText,
    cancelButtonAction,
    changePasswordCallback
  }: PasswordChangeFormProps
) {

  const user = useUser();

  const methods = useForm({ mode: "onChange" });
  const { register, watch, setError } = methods;
  const [ formError, setFormError ] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      setFormError(null);
      setError("oldPassword", { message: undefined });

      try {
        await changePasswordCallback(data.oldPassword, data.newPassword);
      } catch (error) {
        if ((error instanceof ApiError) && (error.error_code === "password_incorrect")) {
          setError("oldPassword", {
            type: "custom",
            message: "Old password is incorrect"
          });
        } else {
          setFormError((error as Error).message || "There was an error changing your password");
        }
      }
    },
    [
      setError, setFormError, changePasswordCallback
    ]
  );

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <div className="flex flex-col space-y-4">
        { askOldPassword && user?.hasPassword && (
          <div className="mb-8">
            <PasswordField
              id="oldPassword"
              label="Old password"
              name="oldPassword"
              minLength={0}
            />
            <ErrorMsg name="oldPassword" />
          </div>
        )}

        <PasswordField
          id="newPassword"
          label="New password"
          {...register(
            "newPassword",
            {
              validate: (value: string) => {
                if (watch("oldPassword") === value) {
                  return "Old password and new password must be different";
                } else {
                  return true;
                }
              }
            }
          )}
        />
        <ErrorMsg name="newPassword" />

        <PasswordField
          id="confirmPassword"
          label="Re-enter new password"
          {...register(
            "confirmPassword",
            {
              validate: (value: string) => {
                if (watch("newPassword") !== value) {
                  return "Passwords do not match";
                } else {
                  return true;
                }
              }
            }
          )}
        />
        <ErrorMsg name="confirmPassword" />
      </div>

      <p className="mt-2 text-sm text-red-600">{formError}</p>

      <div className="mt-8">
        {cancelButtonAction && (
          <div className="flex items-center justify-end gap-x-4">
            <Button text="Cancel" onClick={cancelButtonAction} size="MD" theme="default" />
            <Button
              text={buttonText}
              onClick={() => {}}
              size="MD"
              type="submit"
              Icon={ FormSubmittingSpinner }
              theme="highlighted"
            />
          </div>
        )}

        {!cancelButtonAction && (
          <Button
            text={buttonText}
            theme="highlighted"
            size="LG"
            type="submit"
            Icon={ FormSubmittingSpinner }
            onClick={() => {}}
            fullWidth
          />
        )}

      </div>

    </Form>
  );

};
