import { Listbox } from "@headlessui/react";
import { ListboxButton, ListboxElement, OptionsTransition } from "components/Listbox";
import { cx } from "utils";

export const pickOption = (options: {value: string|undefined, text: string}[], valueToFind: string|undefined) => {
  const option = options.find(({value, text}) => value === valueToFind);
  return option?.text || 'Please select';
}

type SortByListBoxProps = {
  value: string|undefined,
  options: {value: string|undefined, text: string}[],
  callback: (selected: string|undefined) => void,
  className?: string,
  buttonTestId?: string,
};

const listboxButtonClasses = "cursor-pointer px-2.5 py-2.5 bg-white border w-full  pr-10 text-left  relative border-lightGray flex items-center gap-x-2 sm:text-sm";
const listboxOptionsClasses = "absolute py-1 mt-1 overflow-auto text-base bg-white shadow-lg ring-1 ring-lightGray focus:outline-none sm:text-sm z-50"
const listboxOptionClasses = "py-2.5 px-4 hover:bg-brandPrimary hover:text-white cursor-pointer transition z-50"

export const SortByListBox = ({value, options, callback, className, buttonTestId}: SortByListBoxProps) => {
  const appliedClassName = cx("flex flew-row items-center relative", className);
  return (

  <div className={appliedClassName}>
    <div className="white-norwap mr-2.5">Sort by</div>
    <Listbox value={value} onChange={callback}>
      <div className="">
        <Listbox.Button className={listboxButtonClasses} data-testid={buttonTestId}>
          <ListboxButton content={pickOption(options, value)} />
        </Listbox.Button>
        <OptionsTransition>
            <Listbox.Options className={listboxOptionsClasses}>
              {
                options.map((option) => {
                  return (
                    <Listbox.Option key={option.value || 'none'} className={listboxOptionClasses} value={option.value}>
                      <ListboxElement primaryText={option.text}/>
                    </Listbox.Option>
                  );
                })
              }
            </Listbox.Options>
        </OptionsTransition>
      </div>
    </Listbox>
  </div>);
};
