import Button from "components/Button";

interface Props {
  message: string;
  ctaText: string;
  ctaHref: string;
}

export default function Panel({ message, ctaText, ctaHref }: Props) {
  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between px-8 py-4 bg-white border border-borderColor text-gray gap-4 lg:gap-x-6">
      {message}
      <Button text={ctaText} href={ctaHref} theme="default" className="whitespace-nowrap"/>
    </div>
  );
}
