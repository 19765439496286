import { Menu, Listbox, Transition } from "@headlessui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipModel,
  ChartOptions,
  ChartTypeRegistry,
  ScatterDataPoint,
  BubbleDataPoint
} from "chart.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { cx } from "utils";
import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down-icon.svg";
import { ReactComponent as ReportsDropdownIcon } from "assets/icons/reports_dropdown_icon.svg";
import { ReactComponent as ChartStatsIcon } from "assets/icons/chart_stats_icon.svg";
import { ReactComponent as DomainStatsIcon } from "assets/icons/domain_stats_icon.svg";
import scraperApi from "api";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import { DAY } from "utils/timeConstants";
import Spinner from "components/Spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type TimeListOption = { label: string; value: "days" | "hours" | "minutes" };
const ListOptions: TimeListOption[] = [
  { label: "Last Hour", value: "minutes" },
  { label: "Last Day", value: "hours" },
  { label: "Last Week", value: "days" }
];

type SuccessRateTypes = {
  days: SuccessRateType[];
  hours: SuccessRateType[];
  minutes: SuccessRateType[];
};
type SuccessRateType = {
  time: string;
  failures: number;
  successes: number;
  costs: number;
};

const HTMLTooltip = ({
  data,
  position,
  visibility
}: {
  data: TooltipModel<"bar"> | undefined;
  position: { top: number; left: number };
  visibility: boolean;
}) => {
  return (
    <div
      className={cx(
        "absolute p-4 overflow-hidden pointer-events-none transition-all bg-white border shadow duration-300 border-borderColor flex flex-col gap-y-4",
        visibility ? "opacity-100" : "opacity-0"
      )}
      style={{ top: position?.top, left: position?.left }}
    >
      <p className="font-bold text-gray">{data?.title}</p>
      <div className="space-y-2">
        {data?.dataPoints?.map((dataPoint, dataPointIdx) => (
          <div
            key={[ 'div', dataPoint.label, dataPoint.dataset.label, dataPoint.datasetIndex, dataPoint.dataIndex ].join('|')}
            className="flex items-center gap-x-2"
          >
            <div
              className="w-4 h-4"
              style={{
                backgroundColor:
                  data.labelColors[
                    dataPointIdx
                  ].backgroundColor?.toString()
              }}
            />
            <p
              key={[ 'p', dataPoint.label, dataPoint.dataset.label, dataPoint.datasetIndex, dataPoint.dataIndex ].join('|')}
              className="space-x-2"
            >
              <span>{dataPoint.dataset.label}</span>
              <span className="font-bold text-gray">
                {dataPoint.formattedValue}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function SuccessRateHistoryGraph() {
  const [tooltipData, setTooltipData] = useState<TooltipModel<"bar">>();
  const [successRateData, setSuccessRateData] = useState<SuccessRateTypes>();

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = useState<{ top: number; left: number }>();

  const [selectedTime, setSelectedTime] = useState<TimeListOption>(
    ListOptions[0]
  );
  const [generatingReportInProgress, setGeneratingReportInProgress] = useState<boolean>(false);
  // You can open the modal once. One you close it, it won't show up again (until reload)
  // The progress spinner is still there
  // You can remove these on 2023-01-01
  // const [reportInfoModalOpened, setReportInfoModalOpened] = useState<boolean>(false);
  // const [reportInfoModalClosed, setReportInfoModalClosed] = useState<boolean>(false);
  const chartRef = useRef(null);

  // const shouldShowReportInfoModal = reportInfoModalOpened && !reportInfoModalClosed;

  const customTooltip = useCallback(
    (context: {
      chart: ChartJS<
        keyof ChartTypeRegistry,
        (number | ScatterDataPoint | BubbleDataPoint | null)[],
        unknown
      >;
      tooltip: TooltipModel<"bar">;
    }) => {
      const { opacity } = context.tooltip;
      setTooltipData(context.tooltip);
      if (opacity === 0) {
        // Hide tooltip visibilty
        return setTooltipVisible(false);
      }

      const chart = chartRef.current;
      // @ts-ignore
      const canvas = chart?.canvas;
      if (!canvas) return;

      // Enable tooltip visibilty
      setTooltipVisible(true);

      // Set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // Handle tooltip multiple rerender
      if (tooltipPos?.top !== top) {
        setTooltipPos({ top, left });
      }
    },
    [tooltipPos?.top]
  );

  const getNormalizedData = useCallback(
    <T extends TimeListOption["value"]>(
      timeFrame: T,
      resp: SuccessRateTypes
    ): (SuccessRateType & { date: string, backgroundAlpha: number })[] => {
      const dataToShow = resp[timeFrame];
      const fullSlotLength = new Date(dataToShow[1].time).getTime() - new Date(dataToShow[0].time).getTime();

      const data = dataToShow.map((x, idx, arr) => {
        const { value } = selectedTime;
        let opts: Intl.DateTimeFormatOptions = {};
        if (value === "days") {
          opts = { weekday: "long", day: "numeric" };
        }

        if (value === "hours") {
          opts = { weekday: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };
        }

        if (value === "minutes") {
          opts = { hour: "2-digit", minute: "2-digit" };
        }

        let backgroundAlpha = 1;

        if (idx > 0) {
          const slotLength = new Date(x.time).getTime() - new Date(arr[idx - 1].time).getTime();
          backgroundAlpha = (slotLength > fullSlotLength ? slotLength - fullSlotLength : slotLength) / fullSlotLength;
        }

        return {
          ...x,
          date: new Intl.DateTimeFormat("en-US", opts).format(
            new Date(x.time)
          ),
          backgroundAlpha: backgroundAlpha
        };
      });
      return data;
    },
    [selectedTime]
  );

  const successData = useMemo(() => {
    if (!successRateData) return [];
    return getNormalizedData(selectedTime.value, successRateData);
  }, [getNormalizedData, selectedTime, successRateData]);

  const onDownloadCsvClick = useCallback(() => {
    const data = successData;

    const opts = { fields: ["date", "successes", "costs", "failures"] };
    try {
      const blob = new Blob([parse(data, opts)], {
        type: "text/csv;charset=utf-8"
      });
      saveAs(blob, "scraperapi-usage-export.csv");
    } catch (err) {
      console.error(err);
    }
  }, [successData]);

  // 2022-01-01
  const formatDateToYMD = (d: Date) => d.toISOString().slice(0,10);
  const domainReportFileName = () => {
    const now = new Date();
    const sixtyDaysAgo = new Date(now.getTime() - (60 * DAY));
    const reportName = `scraperapi_domainreport_${formatDateToYMD(sixtyDaysAgo)}_${formatDateToYMD(now)}.csv`;
    return reportName;
  }

  const onDownloadPerDomainCsvClick = async () => {
    // setReportInfoModalOpened(true);
    setGeneratingReportInProgress(true);
    const perDomainReport = await scraperApi.stats.perDomainReport();
    setGeneratingReportInProgress(false);
    try {
      const blob = new Blob([ perDomainReport ], {
        type: "text/csv;charset=utf-8;"
      });
      saveAs(blob, domainReportFileName());
    } catch (err) {
      console.error(err);
    }
  };

  const ChartData = useMemo(() => {
    return {
      labels: successData?.map(x => x.date) ?? [],
      datasets: [
        {
          label: "Success",
          data: successData?.map(({ successes }) => Number(successes)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(26, 34, 228, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        },
        {
          label: "Credit",
          data: successData?.map(({ costs }) => Number(costs)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(92, 171, 246, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        },
        {
          label: "Failure",
          data: successData?.map(({ failures }) => Number(failures)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(161, 179, 193, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        }
      ]
    };
  }, [successData]);

  const ChartOptions: ChartOptions<"bar"> = useMemo(() => {
    return {
      responsive: true,
      scales: { x: { grid: { display: false } } },
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: { family: "Work Sans" },
            boxHeight: 12,
            boxWidth: 12,
            padding: 30
          }
        },
        tooltip: {
          enabled: false,
          backgroundColor: "#fff",
          titleColor: "#000",
          borderColor: "#A1B3C1",
          borderWidth: 1,
          mode: "index",
          external: customTooltip
        }
      }
    };
  }, [customTooltip]);

  useEffect(() => {
    const controller = new AbortController();
    scraperApi.auth
      .logs({ signal: controller.signal })
      .then(setSuccessRateData);
    return () => controller.abort();
  }, []);

  return (
    <div className="relative p-8 bg-white border border-borderColor">
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-x-4">
          <div className="text-gray whitespace-nowrap">Monitoring & Stats</div>
          <div>
            <Listbox value={selectedTime} onChange={setSelectedTime}>
              <Listbox.Button className="cursor-pointer px-2.5 py-2.5 bg-white border w-full  pr-10 text-left  relative border-lightGray flex items-center gap-x-2 sm:text-sm">
                {selectedTime.label}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <CaretDownIcon className="w-2.5 h-2.5 text-black" />
                  </span>
              </Listbox.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-lightGray focus:outline-none sm:text-sm">
                  {ListOptions.map((option, i) => (
                    <Listbox.Option
                      className="py-2.5 px-4 hover:bg-brandPrimary hover:text-white cursor-pointer transition"
                      key={`${option.value}-${option.label}-${i}`}
                      value={option}
                    >
                      {option.label}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>
        </div>
        <div>
          {/* Note: You can delete this on 2023-01-01 */}
          {/* { shouldShowReportInfoModal && 
          ( <Modal headline="Loading report" onClose={() => {setReportInfoModalClosed(true)}}>
              <div className="flex flex-col items-center justify-center p-16 gap-x-3 gap-y-3">
                Please wait while we generate your report.
              </div>
            </Modal>)
          } */}
          <Menu>
            <Menu.Button disabled={generatingReportInProgress}>
              <div className="border-lightGray border p-2 h-full">
                { generatingReportInProgress 
                ? <Spinner className="w-4 h-4 text-lightGray animate-spin" />
                : <ReportsDropdownIcon/> }

              </div>
            </Menu.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100 z-30"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items className="absolute right-0 origin-top-right bg-white shadow-lg ring-1 ring-lightGray focus:outline-none">
                <Menu.Item>
                  <div className="gap-5">
                    <button
                      className="block w-full transition-colors hover:bg-slate-200"
                      onClick={onDownloadCsvClick}
                    >
                      <div className="flex items-center gap-x-2 p-2">
                        <ChartStatsIcon/>
                        <span className="whitespace-nowrap">Chart stats</span>
                      </div>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="block w-full transition-colors hover:bg-slate-200"
                    onClick={onDownloadPerDomainCsvClick}
                  >
                    <div className="flex items-center gap-x-2 p-2">
                      <DomainStatsIcon/>
                      <span className="whitespace-nowrap">Domain stats</span>
                    </div>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {tooltipPos && (
        <HTMLTooltip
          data={tooltipData}
          position={tooltipPos}
          visibility={tooltipVisible}
        />
      )}

      <Bar ref={chartRef} options={ChartOptions} data={ChartData} />
    </div>
  );
}
