import { Navigate, Outlet } from "react-router-dom";

import TopLeftLogo from "components/TopLeftLogo";
import OnboardingStepper from "components/onboarding/OnboardingPage/OnboardingStepper";
import useOnboardingStep from "components/onboarding/OnboardingPage/useOnboardingStep";
import { pageData } from "components/onboarding/OnboardingPage/Pages";


export default function OnboardingLayout({ step }: { step?: number }) {
  return (
    <div className="flex flex-col lg:flex-row w-full min-h-full">
      <div className="flex-auto lg:w-[70%]">
        <TopLeftLogo
          content={ <Outlet /> }
          className="lg:min-w-[640px] lg:max-w-[640px] md:min-w-[640px]"
        />
      </div>
      <div className="flex-auto lg:w-[30%] bg-brandLightest p-6">
        <OnboardingStepper step={ step || 1 } />
      </div>
    </div>
  );
};

export function OnboardingQuestionsLayout() {
  const { step } = useOnboardingStep();
  if (!step || step < 2 || step > pageData.length + 1) {
    // TODO would be nicer to only render an error page here, but we're inside a layout which would mess up the screen :/
    //  should be solved by using a data route and do the validation in the loader function, but with the current state
    //  of refactoring it is not possible either :(
    return <Navigate to={ "/404" }/>;
  }

  return (
    <OnboardingLayout step={ step } />
  );
}
