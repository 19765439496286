import { ReactComponent as CSVIcon } from "assets/icons/csv-icon.svg";
import { ReactComponent as JSONIcon } from "assets/icons/json-icon.svg";
import scraperApi from "api";
import { downloadFileUsingTheBlobTrick } from "utils/downloadFileUsingTheBlobTrick";
import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";
import { useState } from "react";
import { cx } from "utils";
import { InfiniteProgressBar } from "components/InfiniteProgressBar";

const buttonClass = "py-2 px-4 border border-slate-200 hover:bg-slate-50";

type InputType = 'upload_json_list' | 'upload_csv_list';

const inputLabels = (inputType: InputType) => {
  return inputType === 'upload_json_list' ? {
    title: 'Upload JSON',
    description: 'Drag & Drop your JSON file or Browse',
    warning: 'Max input size: 50 MB',
    Icon: JSONIcon,
  } : {
    title: 'Upload list',
    description: 'Drag & Drop your list file or Browse',
    warning: 'Max input size: 10,000 entries',
    Icon: CSVIcon,
  }
};

export const UploadFile = (
  {
    inputType,
    projectType,
    fileUploadDone: fileUploadDoneCallback,
    uploadError,
  } : {
    inputType: InputType,
    projectType: string,
    fileUploadDone: (fileName: string, inputKey: string) => void
    uploadError: (err: Error) => void
  }) => {

  const [ isDragging, setDragging ] = useState<boolean>(false);
  const [ isUploading, setUploading ] = useState<boolean>(false);

  const doUploadFile = async(file: File | undefined | null) => {
    if (!file) {
      return;
    }
    try {
      setUploading(true);
      const res = await scraperApi.hostedScraping.uploadProjectInput(file, projectType);
      setUploading(false);
      const id = res.id;
      fileUploadDoneCallback(file.name, id);
    } catch (err) {
      setUploading(false);
      uploadError(err as Error);
    }
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>)=> {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      doUploadFile(file);
    }
  };

  const handleDropEvent = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    doUploadFile(file);
  };

  const { title, Icon, description, warning } = inputLabels(inputType);

  const border = (isDragging && !isUploading) ? 'border-2 border-brandPrimary bg-brandLightest' : 'border border-dashed border-lightGray';

  return (
  <div>
    <div>{title}</div>
    <div
        className={ cx('w-full p-4', border) }
        onDrop={ isUploading ? (ev) => ev.preventDefault() : handleDropEvent}
        onDragOver={(e) => { setDragging(true); e.preventDefault() }}
        onDragLeave={(e) => { setDragging(false); e.preventDefault() }}
      >
      <div className="flex flex-row items-center justify-between gap-2">
        <div className='flex flex-row flex-nowrap items-center gap-4'>
          <div>
            <Icon />
          </div>
          <div>
            <div className="text-sm text-gray font-semibold">{description}</div>
            <div className="text-xs text-gray text-normal">{warning}</div>
          </div>
        </div>
        <label className='w-24 uppercase flex flex-col items-center'>
          <div className={buttonClass}>Browse</div>
          <input className="hidden" type="file" onChange={ isUploading ? (ev) => ev.preventDefault() : handleFileChange } />
        </label>
      </div>
      { isUploading && <InfiniteProgressBar /> }
    </div>
  </div>);
};

const handleDownloadTheCurrentInput = (inputKey: string, fileName: string) => () => {
  scraperApi.hostedScraping.downloadProjectInput(inputKey)
    .then(downloadFileUsingTheBlobTrick(fileName, 'text/plain'))
}

export const DownloadFile = (
    {
      inputType,
      fileName = 'project_input.txt',
      inputKey,
      fileRemovedCallback
    } : {
      inputType: InputType,
      fileName?: string,
      inputKey: string,
      fileRemovedCallback: ()=>void
    }
  ) => {
    const { title, Icon } = inputLabels(inputType);
    return (
    <div>
      <div>{title}</div>
      <div className='w-full border border-lightGray p-4 flex flex-row items-center justify-between gap-2 cursor-pointer' onClick={handleDownloadTheCurrentInput(inputKey, fileName)}>
        <div className='flex flex-row flex-nowrap items-center gap-4'>
          <div>
            <Icon />
          </div>
          <div>
            <div className="text-sm text-gray font-semibold">{fileName}</div>
          </div>
        </div>
        <div className="cursor-pointer p-2 hover:bg-slate-50" onClick={(ev) => { ev.stopPropagation(); fileRemovedCallback() }}><XIcon className="text-gray" /></div>
      </div>
    </div>
  );
};
