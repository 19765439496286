import Button from "components/Button";

import { trackOnboardingEvent } from "utils/Tracking";


export default function ButtonBar(
  {
    nextEnabled,
    skipTrackingEvent
  }: {
    nextEnabled: boolean;
    skipTrackingEvent?: string;
  }
) {
  // TODO should show a spinner and disable the controls when the form is submitting
  return (
    <div className="flex flex-row items-stretch gap-x-3 pt-10">
      <Button
        type={ nextEnabled ? "submit" : "button" }
        text="Next"
        theme={ nextEnabled ? "highlighted" : "primary_disabled" }
        className="w-[150px] md:w-[200px] h-[44px]"
        onClick={ () => {} }
      />
      <Button
        text="Skip"
        theme="borderless_secondary"
        href="/"
        onClick={ () => trackOnboardingEvent(skipTrackingEvent) }
        centerAlign
      />
    </div>
  );

};
