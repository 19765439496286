import { useCallback, useState } from "react";

import { cx } from "utils";

import Button from "components/Button";
import InputFieldWithValidation from "components/InputFieldWithValidation";


export type OnboardingAnswerType = "single-select" | "multi-select" | "readonly-listing" | "text-input";


interface IAnswerProps {
  questionId?: string;
  answer: string;
  mandatory?: boolean;
  onClick?: (event: any) => void;
}

function SingleSelectAnswer({ questionId, answer, mandatory = false, onClick }: IAnswerProps) {
  return (
    <>
      <label onClick={ onClick }>
        <input
          type="radio"
          name={ questionId }
          value={ answer }
          className="peer hidden"
          required={ mandatory }
        />
        <Button
          text={ answer }
          theme="onboarding_option"
          focusable={ false }
          // no onClick nor href, thus we have to apply the styles explicitly here
          className={ cx(
            "peer-checked:text-brandPrimary peer-checked:border-brandPrimary peer-checked:outline peer-checked:outline-1 peer-checked:outline-brandPrimary",
            "hover:border-brandPrimary hover:outline hover:outline-1 hover:outline-brandPrimary",
            "cursor-pointer"
          ) }
        />
      </label>
    </>
  );
}

function MultiSelectAnswer({ questionId, answer, mandatory = false, onClick }: IAnswerProps) {
  return (
    <label
      className="flex flex-row items-center self-start px-4 gap-x-3 text-gray"
      onClick={ onClick }
    >
      <input
        type="checkbox"
        name={ questionId }
        value={ answer }
        required={ mandatory }
      />
      <span>{ answer }</span>
    </label>
  );
}


interface IOnboardingAnswerProps extends IAnswerProps {
  type: OnboardingAnswerType;
}

function OnboardingAnswer(props: IOnboardingAnswerProps) {
  if (props.type === "single-select") {
    return <SingleSelectAnswer { ...props } />;
  }

  if (props.type === "multi-select") {
    return <MultiSelectAnswer { ...props } />;
  }

  if (props.type === "readonly-listing") {
    return (
      <div className="flex flex-row gap-x-3 px-4">
        <span className="border border-gray rounded-full w-1.5 h-1.5 self-center"/>
        <span className="text-gray">{ props.answer }</span>
      </div>
    );
  }

  if (props.type === "text-input") {
    return (
      <div className="flex w-full">
        <InputFieldWithValidation name={ props.questionId } type="text" fullWidth />
      </div>
    );
  }

  return <></>;
}


interface IOnboardingQuestionProps {
  id?: string;
  question: string;
  type: OnboardingAnswerType;
  answers: string[];
  hasOtherOption?: boolean;
  mandatory?: boolean;
  userAnswers?: string[];
  questionClassName?: string;
  showHint?: boolean;
}


export default function OnboardingQuestion(
  {
    id,
    question,
    type,
    answers,
    hasOtherOption = false,
    mandatory = false,
    userAnswers,
    questionClassName,
    showHint = true
  }: IOnboardingQuestionProps
) {
  const [ isOtherInputVisible, setOtherInputVisible ] = useState(userAnswers?.includes("Other"));

  const updateOtherInputVisibility = useCallback((event: any) => {
    const inputControl = event.currentTarget.control;

    if (inputControl.type === "radio") {
      setOtherInputVisible((inputControl.value === "Other") && inputControl.checked);
    } else if (inputControl.type === "checkbox") {
      if (inputControl.value === "Other") {
        setOtherInputVisible(inputControl.checked);
      }
    }
  }, []);


  return (
    <div className="flex flex-col items-start gap-y-3">
      <div className="flex flex-col xs:flex-row items-start xs:items-end gap-x-2">
        {/*
      or as an alternative, we can simply use flex-wrap to put the 'select X option' part below the question, dynamically
      <div className="flex flex-row flex-wrap items-baseline gap-x-2">
      */ }
        <span className={ questionClassName || "text-xl text-gray font-semibold" }>
          { question }
        </span>
        { showHint && ((type === "single-select" || type === "multi-select")) && (
          <span className="text-sm text-gray pb-1">
            { type === "single-select" && "(Select one option)" }
            { type === "multi-select" && "(Select multiple options)" }
          </span>
        ) }
      </div>

      <div className={ cx(
        type === "single-select" && "flex flex-col xxs:flex-row flex-wrap gap-x-4 gap-y-2",
        type === "text-input" && "flex flex-row w-full",
        ((type !== "single-select" && type !== "text-input") && "flex flex-col gap-y-3"))
      }>
        { answers.map((answer, idx) =>
          <OnboardingAnswer
            key={ idx }
            questionId={ id }
            answer={ answer }
            type={ type }
            mandatory={ mandatory }
            onClick={ updateOtherInputVisibility }
          />
        ) }

        { hasOtherOption && (
          <OnboardingAnswer
            questionId={ id }
            answer="Other"
            type={ type }
            mandatory={ mandatory }
            onClick={ updateOtherInputVisibility }
          />
        ) }
      </div>

      { hasOtherOption && isOtherInputVisible && (
        <div className={ cx("w-full", (type === "multi-select") && "px-4") }>
          <InputFieldWithValidation
            autoFocus
            type="text"
            name={ id + "_Other" }
            placeholder={ "Type in your answer" }
            required
          />
        </div>
      ) }

    </div>
  );
};
