import scraperApi from "api";

import { setCookie } from "utils/cookieUtils";

import AuthForm from "components/AuthForm";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import useNewOnboardingFeatureSwitch from "components/onboarding/useNewOnboardingFeatureSwitch";


export default function Signup() {
  const useNewOnboarding = useNewOnboardingFeatureSwitch();

  if (useNewOnboarding) {
    // have to set this cookie to redirect to the new onboarding after GitHub signup
    setCookie("sapiTargetUrl", "/welcome/2", { expires: 1 });
  }

  return (
    <AuthPage
      title="Sign up"
      heading={[
        "Create an account",
        "Get started with 5000 free API credits. No credit card required. Cancel anytime."
      ]}
    >
      <AuthForm
        googleButtonMode="signup"
        googleButtonText="Sign up with Google"
        googleCallback={scraperApi.auth.google.signup}
        googleRedirect={useNewOnboarding ? "/welcome/2" : undefined }
        gitHubButtonText="Sign up with GitHub"
        gitHubRedirectUri={process.env.REACT_APP_API_URL + "/oauth/github/sign-up"}
        emailButtonText="Sign up with email"
        emailCallback={scraperApi.auth.signup}
        showReCaptcha
        showTerms
        defaultErrorMessage="There was an error signing up. Please try again later."
      />

      <AuthPageLinks>
        <AuthPageLink text="Already have an account?" link={{ to: "/login", text: "Login here" }} />
        <AuthPageLink text="Having trouble signing up? Contact" link={{ to: "mailto:support@scraperapi.com", text: "support@scraperapi.com" }} />
      </AuthPageLinks>
    </AuthPage>
  );
}
