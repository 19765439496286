import { ReactNode } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { cx } from "utils";

type DetailsSwitch = 'up' | 'down' | undefined;

type InputCommentProps = {
  title: string,
  description: string | ReactNode,
  showDetailsSwitch?: DetailsSwitch,
  onDetailsClicked?: ()=>void,
  testId?: string,
}

export const InputComments = ({title, description, showDetailsSwitch, onDetailsClicked, testId}: InputCommentProps) => {
  const showDetails = showDetailsSwitch === 'up' || showDetailsSwitch === 'down';
  const showDetailsStyle = showDetails ? 'cursor-pointer' : '';
  const showDetailsClickHandler = showDetails ? onDetailsClicked : undefined;
  return (<div className="w-80">
    <div className={cx("text-base font-semibold text-gray flex flex-row items-center mb-1", showDetailsStyle)} data-testid={testId} onClick={showDetailsClickHandler} >
      { showDetailsSwitch === 'up' && <ChevronDownIcon className="w-4 h-4 mr-2" />}
      { showDetailsSwitch === 'down' && <ChevronUpIcon className="w-4 h-4 mr-2" /> }
      {title}
    </div>
    {
      showDetailsSwitch!== 'down' && (<div className="text-sm font-normal text-lightGray-800 pr-1">
        {description}
      </div>)
    }
  </div>);
}
