import BorderedPage from "components/BorderedPage";

import { AsyncUrlBar } from "components/hosted-scraping/select-type-components/AsyncUrlBar";
import { StructuredDatasourceCard } from "components/hosted-scraping/select-type-components/StructuredDatasourceCard";
import { TypeSelectedCallback } from "components/hosted-scraping/select-type-components/typeSelectedCallback";
import { validSDECollectorTypes } from "sdecontent";

export function ApiPlaygroundSelectType({ callback }: { callback: TypeSelectedCallback }) {
  return (
    <BorderedPage title="API Request Creator">
      <p className="mt-2 text-xl font-normal text-brandDarkest">
        What type of data do you need?
      </p>
      <p className="mt-3 text-sm text-gray-600">
        ScraperAPI allows you to extract data from URLs or specific domains and paths, such as Google search results or Amazon product pages. With the specific domains, you will be able to download a JSON file with well-structured data. Simply choose the type of data you need and we'll handle the rest.
      </p>

      <div className="mt-8">
        <AsyncUrlBar variant="api-playground" callback={callback} />
      </div>

      <div className="mt-24 mb-3 text-base">
        Structured data sources
      </div>
      <div className="flex flex-row flex-wrap gap-6">
        {validSDECollectorTypes.filter((type) => (type !== 'async_urls')).map((type) => <StructuredDatasourceCard variant="api-playground" key={type} configType={type} callback={callback} />)}
      </div>
    </BorderedPage>
  );
}
