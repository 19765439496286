import { NavigateFunction, useNavigate } from "react-router-dom";

import { iconTypeHS, projectTypeHS } from "sdecontent";
import { ProjectConfigWithStats } from "providers/HostedScrapingProvider/types";
import { ReactComponent as AddNewProjectIcon } from "assets/icons/project-add-new.svg";
import { ProjectStatus } from "./ProjectStatus"; 
import { formatDate } from "./ProjectFormatDate"; 
import { projectIcon } from "components/hosted-scraping/ProjectIcon";
import { NavLink } from "react-router-dom";

const lineClassName = 'font-normal text-sm text-gray';
const headerClasses = 'font-droid text-slate-400 text-xs font-medium';

const NewProjectButton = () => (
  <NavLink to="/projects/new">
    <div className="flex flex-row p-3 hover:bg-slate-50">
      <div className="w-8 h-8 border border-slate-200 bg-brandPrimary flex flex-col items-center justify-center">
        <AddNewProjectIcon/>
      </div>
      <div className="flex flex-col text-gray-700 ml-2 font-medium text-base align-middle justify-center">
      New project
      </div>
    </div>
  </NavLink>
)

type IProjectNameAndTypeProps = {
  project: ProjectConfigWithStats,
}

const ProjectNameAndType = ({project}: IProjectNameAndTypeProps) => { 
  const Icon = projectIcon(iconTypeHS(project.config.type));
  return (
  <div className="flex flex-row flex-start h-16 pt-3 pb-3 pl-2.5">
    <div className="w-10 h-10 p-2 border border-slate-200 flex items-center justify-center">
      <Icon/>
    </div>
    <div className="flex flex-col pl-2">
      <div className="font-medium text-base text-gray-700 overflow-hidden whitespace-nowrap">{project.name}</div>
      <div className="font-normal text-xs text-slate-400 overflow-hidden whitespace-nowrap">{projectTypeHS(project.config.type)}</div>
    </div>
  </div>
)};

const navigateToProject = (navigate: NavigateFunction, projectId: number) => () => {
  navigate(`/projects/${encodeURIComponent(projectId)}`);
}

const projectLine = (navigate: NavigateFunction, project: ProjectConfigWithStats) => (
    <tr onClick={navigateToProject(navigate, project.id)} className="hover:bg-slate-50 cursor-pointer" key={project.id}>
      <td className={lineClassName}><ProjectNameAndType project={project}/></td>
      <td className={lineClassName}><ProjectStatus project={project}/></td>
      <td className={lineClassName}>{formatDate(project.lastRunAt)}</td>
      <td className={lineClassName}>{project.credits}</td>
      {/* <td className={lineClassName}>...</td> */}
    </tr>
);

const projectListHeader = () => (
  <tr className="text-left">
    <th><div className="h-16 flex justify-center flex-col"><NewProjectButton/></div></th>
    <th className={headerClasses}>STATUS</th>
    <th className={headerClasses}>LAST RUN</th>
    <th className={headerClasses}>CREDITS SPENT</th>
    {/* <th className={headerClasses}></th> */}
  </tr>
)

type IProjectListProps = {
  projects: ProjectConfigWithStats[],
}


export default function ProjectListView({projects}: IProjectListProps) {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white border border-solid border-slate-200">
      <div className="p-6 w-full overflow-auto">
          <table className="w-full divide-y divide-slate-200">
            <thead>
              {projectListHeader()}
            </thead>
            <tbody className="w-full divide-y divide-slate-200">
              {projects.map((project) => projectLine(navigate, project))}
            </tbody>
          </table>
      </div>
    </div>
  );
}
