import { useCallback, useEffect, useState } from "react";

import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";


interface ICopyToClipboardTextProps {
  text: string;
  notificationTimeoutMs?: number;
}

export default function CopyToClipboardText(
  {
    text,
    notificationTimeoutMs = 3000
  }: ICopyToClipboardTextProps
) {
  const [ recentlyCopied, setRecentlyCopied ] = useState(false);

  useEffect(() => {
    const timerHandler = setTimeout(() => setRecentlyCopied(false), notificationTimeoutMs);
    return () => clearTimeout(timerHandler);
  }, [ notificationTimeoutMs, recentlyCopied ]);

  const copyToClipboardCallback = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(text);
      setRecentlyCopied(true);
    } catch (error) {
      // TODO error handling when copying to clipboard fails
    }
  }, [ text ]);

  return (
    <div
        className="flex items-center justify-between w-full gap-x-2 cursor-pointer"
        onClick={ copyToClipboardCallback }
    >
      <span className="inline-block text-gray text-sm truncate">{ text }</span>
      <div className="flex items-center text-gray gap-x-2">
        { recentlyCopied && "Copied" }
        <CopyIcon className="w-5 h-5 text-gray-50" />
      </div>
    </div>
  );
};
