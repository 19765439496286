import { ProjectIcon } from "sdecontent/sdecontentTypes";

import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";
import { ReactComponent as AmazonIcon } from "assets/icons/amazon-icon.svg";
import { ReactComponent as RawUrlsIcon } from "assets/icons/raw-urls-icon.svg";
import { ReactComponent as ExperimentalIcon } from "assets/icons/funnel-icon.svg";
import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo-no-text.svg";
import { ReactComponent as WalmartIcon } from "assets/icons/walmart-icon_2.svg";
import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";


export const projectIcon = (iconType: ProjectIcon): any => {
  switch (iconType) {
    case 'experimental': return ExperimentalIcon;
    case 'scraperapi': return ScraperAPILogo;
    case 'urls': return RawUrlsIcon;
    case 'amazon': return AmazonIcon;
    case 'google': return GoogleIcon;
    case 'walmart': return WalmartIcon;
    case 'unknown':
    default: return XIcon;
   }
}
