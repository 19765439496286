import { ReactElement, ReactNode } from 'react';
import { Title } from 'react-head';
import { Link } from 'react-router-dom';


interface AuthPageLinkProps {
  text: string;
  link: {
    to: string;
    text: string
  }
}

export function AuthPageLink(
  {
    text,
    link
  }: AuthPageLinkProps
) {
  return (
    <div>
      {text + " "}
      <Link
        to={link.to}
        className="underline transition-colors text-gray hover:text-gray-800 whitespace-nowrap"
      >
        {link.text}
      </Link>
    </div>
  );
}


interface AuthPageLinksProps {
  children: ReactNode
}

export function AuthPageLinks({ children }: AuthPageLinksProps) {
  return (
    <div className="flex flex-col items-center text-center space-y-8 mt-8 text-base text-gray">
      {children}
    </div>
  );
}


interface AuthPageProps {
  title: string,
  heading: string | ReactElement | (string | ReactElement)[],
  children: ReactNode
}


export default function AuthPage({
                                      title,
                                      heading,
                                      children
                                    }: AuthPageProps
) {
  const headingText = Array.isArray(heading) ? heading[0] : heading;
  const headingDetails = Array.isArray(heading) ? heading[1] : undefined;

  return (
    <>
      <Title>ScraperAPI | {title}</Title>

      <div className="flex flex-col mb-16 space-y-2 text-center lg:text-left">
        <div className="text-2xl text-brandDarkest">{headingText}</div>
        {headingDetails && (
          <div className="text-sm text-gray">{headingDetails}</div>
        )}
      </div>

      {children}
    </>
  );

};
