import { RadioGroup } from "@headlessui/react";

const radiogroupPropertiesForHeadlessUI = `
relaive float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 border-solid border-[rgba(0,0,0,0.25)] bg-primary 
before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] 
after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:bg-primary after:content-[''] 
checked:border-primary checked:bg-primary checked:before:opacity-[0.16] 
checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-blue checked:after:bg-blue checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
`;

export const RadioGroupElement = ({value, label}: {value: string, label: string}) => {
    return (
      <RadioGroup.Option value={value}>
        { ({ checked }) => (
        <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
          <input
            className={radiogroupPropertiesForHeadlessUI}
            type="radio"
            checked={checked}
            onChange={() => {}}
            />
          <label
            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
            htmlFor={value}>
            {label}
          </label>
        </div>
        )}
      </RadioGroup.Option>
    );
};