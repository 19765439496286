import { Switch } from "@headlessui/react";
import { useUserProvider } from "providers/UserProvider";
import { canUsePremium, canUseUltraPremium, canUseAdvancedGeotargeting } from "utils/planUtils";
import type { CollectorConfig } from "providers/HostedScrapingProvider/types";
import { Tooltip } from "components/Tooltip";
import { useSettingsFromApi } from "providers/ApiSettingsProvider";
import { ConfigProblem, ConfigProblemWithMessage } from "components/hostedScrapingValidators";
import { tooltips } from "./tooltips";
import { VALID_AMAZON_TLDS, VALID_GOOGLE_TLDS, VALID_WALMART_TLDS } from "./validTLDs";
import { ProjectVariant } from "sdecontent";
import { disableFollowRedirectToFollowRedirect, followRedirectToDisableFollowRedirect } from "providers/HostedScrapingProvider/negateFollowRedirects";
import { AdditionalOptionsTextBox } from "./AdditionalOptionsTextBox";
import { AdditionalOptionsListbox } from "./AdditionalOptionsListBox";

const availableDeviceTypes = [
  {text: 'None', value: undefined},
  {text: 'Desktop', value: 'desktop'},
  {text: 'Mobile', value: 'mobile'}
];

const countryCodes = (availableCountryCodes: string[]) => [
  {text: 'None', value: undefined},
  ...(availableCountryCodes.map((code) => ({text: code, value: code})))
];

const restrictedCountryCodes = [
  {text: 'None', value: undefined},
  {text: 'us', value: 'us'},
  {text: 'eu', value: 'eu'},
];

type AdditionalOptionsSwitchProps = {
  primaryText: string,
  secondaryText?: string,
  checked: boolean,
  callback: (state: boolean) => void,
  testId?: string
};

const AdditionalOptionsSwitch = ({primaryText, secondaryText, checked, callback, testId}: AdditionalOptionsSwitchProps ) => {
  return (<div className="mt-5 flex flex-row flex-wrap gap-2">
    <Switch
      data-testid={testId}
      checked={checked}
      onChange={callback}
      className={`${
        checked ? 'bg-blue-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full z-0`}
    >
      <span
        className={`${
          checked ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
    <div>
      <span className="text-normal">
        {primaryText}
      </span>
      {secondaryText && <span className="text-sm ml-1">
        {secondaryText}
      </span>}
    </div>
  </div>);
}

const urlProjectTextsHS = {
  premium: "Activate premium residential and mobile IPs",
  ultraPremium:"Activate advanced bypass mechanism",
  redirect: "Disable follow redirects",
  rendering: "Activate javascript rendering",
  autoparse: "Activate to parse results",
  follow404: "Activate retrying 404 responses",
  sessionNumber: "Session number",
  binaryTarget: "Binary target",
  keepHeaders: "Use own headers",
}

const urlProjectTextsAP = {
  premium: "Premium residential and mobile IPs",
  ultraPremium:"Advanced bypass mechanism",
  redirect: "Disable follow redirects",
  rendering: "Javascript rendering",
  autoparse: "Parse results",
  follow404: "Retry 404 responses",
  sessionNumber: "Session number",
  binaryTarget: "Binary target",
  keepHeaders: "Use own headers",
}

const hashasProblem = (problems: ConfigProblemWithMessage[]) => (problem: ConfigProblem) => problems.some(p => p.problem === problem); 

type UrlProjectAsyncApiParamsProps = {
  variant: ProjectVariant;
  collectorConfig: CollectorConfig;
  problems: ConfigProblemWithMessage[];
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined) => void;
};

export const UrlProjectAsyncApiParams = ({variant, collectorConfig, problems, updateApiParams}: UrlProjectAsyncApiParamsProps) => {
  const { subscription } = useUserProvider();
  const { availableCountryCodes } = useSettingsFromApi();
  // const config = collectorConfig as AsyncUrls;
  const countryCodesToShow = canUseAdvancedGeotargeting(subscription) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const texts = variant === 'hosted-scraper' ? urlProjectTextsHS : urlProjectTextsAP;

  const hasProblem = hashasProblem(problems);

  return (
    <>
      { canUsePremium(subscription?.plan_id) &&
        <Tooltip content={tooltips.activatePremiumResidentialAndMobileIps} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.premium} checked={Boolean(collectorConfig.apiParams?.premium)} callback={updateApiParams('premium')} testId="testPremiumSwitch"/>
        </Tooltip>
      }

      { canUseUltraPremium(subscription?.plan_id) &&
        <Tooltip content={tooltips.activateAdvancedBypassMechanism} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.ultraPremium} checked={Boolean(collectorConfig.apiParams?.ultraPremium)} callback={updateApiParams('ultraPremium')} testId="testUltraPremiumSwitch"/>
        </Tooltip>
      }

      <Tooltip content={tooltips.disableFollowRedirects} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.redirect} 
          checked={Boolean( followRedirectToDisableFollowRedirect(collectorConfig.apiParams?.followRedirect as boolean | undefined))} 
          callback={(newValue) => updateApiParams('followRedirect')(disableFollowRedirectToFollowRedirect(newValue)) }
          testId="testRedirectsSwitch"/>
      </Tooltip>

      {/* <AdditionalOptionsSwitch primaryText="Keep headers" checked={Boolean(project.config?.apiParams?.keepHeaders)} callback={updateApiParams('keepHeaders')}/> */}

      <Tooltip content={tooltips.activateJavascriptRendering} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.rendering} checked={Boolean(collectorConfig.apiParams?.render)} callback={updateApiParams('render')} testId="testRenderSwitch"/>
      </Tooltip>

      {collectorConfig.apiParams?.render &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.waitForSelector}>Wait for selector</Tooltip>
          </div>
          <AdditionalOptionsTextBox value={collectorConfig.apiParams?.waitForSelector as string | undefined} placeholder="Please add selector" callback={updateApiParams('waitForSelector')} />
        </>
      }

      <Tooltip content={tooltips.autoparse} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.autoparse} checked={Boolean(collectorConfig.apiParams?.autoparse)} callback={updateApiParams('autoparse')}/>
      </Tooltip>

      <Tooltip content={tooltips.activateRetrying404Responses} className="mt-5">
        <AdditionalOptionsSwitch primaryText={texts.follow404} checked={Boolean(collectorConfig.apiParams?.retry404)} callback={updateApiParams('retry404')}/>
      </Tooltip>

      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode"/>
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.deviceType}>Device type</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig.apiParams?.deviceType as string | undefined} options={availableDeviceTypes} callback={updateApiParams('deviceType')} buttonTestId="testDeviceType" />

      { variant === 'api-playground' &&
      <>
        <div className="mt-5">
          <Tooltip content={tooltips.sessionNumber}>Session number</Tooltip>
        </div>
        <AdditionalOptionsTextBox value={collectorConfig.apiParams?.sessionNumber as string | undefined} placeholder="Please set a session number" callback={(newValue: string | undefined) => {
          if (newValue === undefined) {
            updateApiParams('sessionNumber')(undefined);
            return;
          }
          const parsed = Number.parseInt(newValue, 10);
          if (Number.isNaN(parsed)) {
            // TODO: show error - invalid number
          } else {
            updateApiParams('sessionNumber')(parsed);
          }
        }} />

        {/* { config.apiParams?.autoparse &&
        <>
          <div className="mt-5">
            Select format
          </div>
          <AdditionalOptionsListbox
            value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
            options={[
              { value: 'csv', text: 'CSV' },
              { value: 'json', text: 'JSON' }
            ]}
            callback={updateApiParams('outputFormat')}
          />
        </>
        } */}

        <Tooltip content={tooltips.binaryTarget} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.binaryTarget} checked={Boolean(collectorConfig.apiParams?.binaryTarget)} callback={updateApiParams('binaryTarget')}/>
        </Tooltip>

        <Tooltip content={tooltips.keepHeaders} className="mt-5">
          <AdditionalOptionsSwitch primaryText={texts.keepHeaders} checked={Boolean(collectorConfig.apiParams?.keepHeaders)} callback={updateApiParams('keepHeaders')}/>
        </Tooltip>
      </>
      }
    </>
  );
}

type AmazonProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | undefined) => void,
  checkSubscription: boolean
}

export const AmazonProjectAsyncApiParams = ({collectorConfig, problems, updateApiParams, checkSubscription}: AmazonProjectAsyncApiParamsProps) => {
  const { subscription } = useUserProvider();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = (!checkSubscription || canUseAdvancedGeotargeting(subscription)) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    {text: 'None', value: undefined},
    ...VALID_AMAZON_TLDS.map(tld => ({text: tld, value: tld}) )
  ];

  return (
    <>
      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode"/>
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList/>
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500">Invalid TLD</div>}

      {/* <div className="mt-5">
        Select format
      </div>
      <AdditionalOptionsListbox
        value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
        options={[
          { value: 'csv', text: 'CSV' },
          { value: 'json', text: 'JSON' }
        ]}
        callback={updateApiParams('outputFormat')}
        /> */}
    </>
  );
}

type GoogleProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: number | string | boolean | undefined) => void,
  checkSubscription: boolean
}
export const GoogleProjectAsyncApiParams = ({collectorConfig, problems, updateApiParams, checkSubscription}: GoogleProjectAsyncApiParamsProps) => {
  const { subscription } = useUserProvider();
  const { availableCountryCodes } = useSettingsFromApi();
  const countryCodesToShow = (!checkSubscription || canUseAdvancedGeotargeting(subscription)) ? countryCodes(availableCountryCodes) : restrictedCountryCodes;

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    {text: 'None', value: undefined},
    ...VALID_GOOGLE_TLDS.map(tld => ({text: tld, value: tld}) )
  ];


  return (
    <>
      <div className="mt-5">
        <Tooltip content={tooltips.countryCode}>Country code</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.countryCode as string | undefined} options={countryCodesToShow} callback={updateApiParams('countryCode')} shortList buttonTestId="testCountryCode"/>
      {hasProblem(ConfigProblem.InvalidCountryCode) && <div className="text-red-500">Invalid country code</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList/>
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500">Invalid TLD</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.UULE}>UULE</Tooltip>
      </div>
      <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.uule as string | undefined} placeholder="Please enter a UULE" callback={updateApiParams('uule')} />
      {hasProblem(ConfigProblem.InvalidUULE) && <div className="text-red-500">Invalid UULE</div>}

      <div className="mt-5">
        <Tooltip content={tooltips.numberOfResults}>Number of results</Tooltip>
      </div>
      <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.num as string | undefined} placeholder="Please set the number of results" callback={updateApiParams('num')} />
      {hasProblem(ConfigProblem.InvalidNum) && <div className="text-red-500">Invalid number</div>}

      {/* <div className="mt-5">
        Select format
      </div>
      <AdditionalOptionsListbox
        value={config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
        options={[
          { value: 'csv', text: 'CSV' },
          { value: 'json', text: 'JSON' }
        ]}
        callback={updateApiParams('outputFormat')}
        /> */}

      {collectorConfig.type === 'async_google_search' &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.additionalParams}>Additional parameters</Tooltip>
          </div>
          <textarea className="w-full border-gray-200 placeholder-gray-200 text-sm" value={collectorConfig?.apiParams?.additionalParams as string} placeholder='Enter additional parameters and separate with "&" (ampersand) sign.' onChange={(ev) => updateApiParams('additionalParams')(ev.target.value)}/>
          {hasProblem(ConfigProblem.InvalidAdditionalParameters) && <div className="text-red-500">Invalid parameters</div>}
        </>
      }
    </>
  );
}


type WalmartProjectAsyncApiParamsProps = {
  collectorConfig: CollectorConfig,
  problems: ConfigProblemWithMessage[],
  updateApiParams: (key: string) => (newValue: string | boolean | undefined) => void
}

export const WalmartProjectAsyncApiParams = ({collectorConfig, problems, updateApiParams}: WalmartProjectAsyncApiParamsProps) => {

  const hasProblem = hashasProblem(problems);

  const domainsToShow = [
    {text: 'None', value: undefined},
    ...VALID_WALMART_TLDS.map(tld => ({text: tld, value: tld}) )
  ];

  const validSortByOptions = [
    {text: 'None', value: undefined},
    {text: 'Relevancy', value: 'relevancy'},
    {text: 'Helpfulness', value: 'helpful'},
    {text: 'Submission - descending', value: 'submission-desc'},
    {text: 'Submission - ascending', value: 'submission-asc'},
    {text: 'Rating - descending', value: 'rating-desc'},
    {text: 'Rating - ascending', value: 'rating-asc'},
  ];

  return (
    <>
      <div className="mt-5">
        <Tooltip content={tooltips.topLevelDomain}>Domain</Tooltip>
      </div>
      <AdditionalOptionsListbox value={collectorConfig?.apiParams?.tld as string | undefined} options={domainsToShow} callback={updateApiParams('tld')} shortList/>
      {hasProblem(ConfigProblem.InvalidTLD) && <div className="text-red-500">Invalid TLD</div>}

      { (collectorConfig.type === 'async_walmart_search' || collectorConfig.type === 'async_walmart_category' || collectorConfig.type === 'async_walmart_review') &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.walmartPage}>Page</Tooltip>
          </div>
          <AdditionalOptionsTextBox value={collectorConfig?.apiParams?.page as string | undefined} placeholder="Please enter page number" callback={updateApiParams('page')} />
          {hasProblem(ConfigProblem.InvalidNum) && <div className="text-red-500">Invalid page number</div>}
        </>
      }
      { (collectorConfig.type === 'async_walmart_review') &&
        <>
          <div className="mt-5">
            <Tooltip content={tooltips.walmartPage}>Sort by</Tooltip>
          </div>
        <AdditionalOptionsListbox value={collectorConfig?.apiParams?.sort as string | undefined} options={validSortByOptions} callback={updateApiParams('sort')} shortList/>
        {hasProblem(ConfigProblem.InvalidSortBy) && <div className="text-red-500">Invalid sort by</div>}
        </>
      }
    </>
  );
}