import { Menu, Transition } from "@headlessui/react";

import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up-s-line.svg";

import * as MenuItems from "./MenuItems";

import { useUser } from "routes/dataroutes/UserData";

import { cx } from "utils";
import { getUsername } from "utils/userUtils";


export function UserBadge({ initial }: { initial: string }) {
  return (
    <div className="flex items-center justify-center w-6 h-6 bg-gray rounded-full">
      <span className="text-white font-semibold text-sm">{ initial }</span>
    </div>
  );
}

function UsernameAndEmail({ username, email }: { username: string, email: string }) {
  return (
    <div className="flex flex-col items-start">
      <span className="text-sm font-semibold text-gray -mb-1 max-w-[160px] truncate">{ username }</span>
      <span className="text-xs text-gray max-w-[160px] truncate">{ email }</span>
    </div>
  );
}

type MenuItemList = {
  items?: MenuItemList[];
  disabled?: boolean;
  menuItem?: (close?: CloseFn) => JSX.Element;
};

type CloseFn = () => void;

function RenderMenuItems({ menuItems, closeFn, indexPrefix }: { menuItems: MenuItemList[], closeFn?: CloseFn, indexPrefix?: string }) {
  return (
    <>
      { menuItems.map((item, index) => {
        const itemIndex = indexPrefix ? indexPrefix + "-" + index : index.toString(10);

        if (item.items) {
          return (
            <div className="py-3" key={ itemIndex }>
              <RenderMenuItems menuItems={ item.items } indexPrefix={ itemIndex }/>
            </div>
          );
        }

        if (item.menuItem) {
          return (
            <Menu.Item disabled={ item.disabled } key={ itemIndex }>
              <div>{ item.menuItem?.(closeFn) }</div>
            </Menu.Item>
          );
        }

        return <></>;
      }) }
    </>
  );
}

/**
 * Should never be used before login.
 */
export function UserMenuControlled({ showMenu, close, widthClassName }: { showMenu: boolean, close: () => void, widthClassName?: string }) {
  const user = useUser();

  const menuItems = [
    {
      items: [
        {
          disabled: true,
          menuItem: () => <MenuItems.AccountEmail />
        },
        {
          disabled: true,
          menuItem: () => <MenuItems.AccountApiKey />
        }
      ]
    },
    {
      menuItem: (close?: CloseFn) => <MenuItems.ChangePassword onClick={ close } />,
    },
    {
      disabled: user?.isBlocked,
      menuItem: (close?: CloseFn) => <MenuItems.ManageApiKeys onClick={ close } />,
    },
    // TODO onboarding "how to get started quickly" button is missing here
    {
      disabled: true,
      menuItem: (close?: CloseFn) => <MenuItems.Logout onClick={ close } />
    }
  ];

  return <Menu>
    <Transition
      show={ showMenu }
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <div className="absolute bottom-16 right-2.5 z-30">
        <Menu.Items static className={ cx("origin-bottom-left bg-white shadow-lg ring-1 ring-lightGray focus:outline-none divide-y", widthClassName ?? "w-72") }>
          <RenderMenuItems menuItems={ menuItems } closeFn={ close } />
        </Menu.Items>
      </div>
    </Transition>
  </Menu>
}

export function UserMenuButtonControlled({ open }: { open?: boolean }) {
  const user = useUser()!;
  const username = getUsername(user);

  return (
    <div className="flex flex-row items-center justify-between" data-user-email={ user.email }>
      <div className="flex flex-row gap-x-1.5 items-center">
        <UsernameAndEmail username={ username } email={ user.email } />
      </div>
      <ArrowUpIcon className={ cx("w-4 h-4 transition-transform duration-300", open && "-rotate-180") } />
    </div>
  );
}
