import { useCallback, useEffect, useRef, useState } from "react";
import { PlusIcon } from  "@heroicons/react/outline"

import { ReactComponent as Scraping2Illustration } from "assets/images/scraperapi-illustration2.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";
import { ReactComponent as DataPipelineIcon } from "assets/icons/data-pipeline.svg";
import { ReactComponent as ApiPlaygroundIcon } from "assets/icons/api-playground.svg";

import ExtLink from "components/ExtLink";
import { QuickLinkCard } from "components/QuickLinkCard";

import { useUser } from "routes/dataroutes/UserData";


const CopyField = ({ label, value }: { label: string; value: string }) => {
  let timerHandle = useRef<number>();
  const [recentlyCopied, setRecentlyCopied] = useState(false);

  useEffect(() => {
    timerHandle.current = window.setTimeout(
      () => setRecentlyCopied(false),
      3000
    );

    // Clear the timer if we unmount before completion
    return () => clearTimeout(timerHandle.current);
  }, [recentlyCopied, value]);

  const onClick = useCallback(async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setRecentlyCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  }, []);

  return (
    <div onClick={() => onClick(value)} className="overflow-hidden">
      <span className="block mb-1 text-sm text-lightGray">{label}</span>
      <div className="relative flex items-center justify-between flex-shrink-0 px-3 py-2 text-base border cursor-pointer border-lightGray text-gray whitespace-nowrap">
        {value}
        <div className="absolute right-0 flex items-center pl-2 pr-3 bg-white gap-x-2">
          {recentlyCopied && "Copied"}
          <CopyIcon className="flex-shrink-0 w-5 h-5 text-lightGray" />
        </div>
      </div>
    </div>
  );
};

export default function OnboardingCard({
  apiKey
}: {
  apiKey: string;
  onHide: () => void;
}) {
  const user = useUser();

  return (
    <div className="flex flex-col p-8 bg-white border border-borderColor">
      <span className="text-2xl mb-5">What do you want to do today?</span>
      <div className="flex flex-row flex-wrap gap-5">
        <QuickLinkCard Icon={PlusIcon} title="Create new DataPipeline project" description="Extract data from URLs or specific domains from the dashboard." to="/projects/new" disabled={ user?.isBlocked }/>
        <QuickLinkCard Icon={DataPipelineIcon} title="Manage DataPipeline projects" description="View and edit existing DataPipeline projects and download your results." to="/projects" disabled={ user?.isBlocked }/>
        <QuickLinkCard Icon={ApiPlaygroundIcon} title="Create API request in API Playground" description="Build your request, see credit costs for this request, download the code and test run your URL" to="/apiplayground" disabled={ user?.isBlocked }/>
      </div>
      <div className="flex gap-x-24">
        <div className="flex flex-col space-y-2 w-full self-center">
          <span className="text-2xl">Getting Started</span>
          <span className="text-sm text-gray">
            Using ScraperAPI is easy. Just send the URL you would like to scrape
            to the API along with your API key and the API will return the HTML
            response from the URL you want to scrape.
          </span>
        </div>
        <div className="pr-3 hidden lg:block">
          <Scraping2Illustration />
        </div>
      </div>
      <div className="flex flex-col my-8 space-y-4">
        <CopyField label="API Key" value={apiKey} />
        <CopyField
          label="Sample Async Code"
          value={`curl -X POST -H "Content-Type: application/json" -d '{"apiKey": "${apiKey}", "url": "http://httpbin.org/ip"}' "https://async.scraperapi.com/jobs"`}
        />
        <CopyField
          label="Sample API Code"
          value={`curl "http://api.scraperapi.com?api_key=${apiKey}&url=http://httpbin.org/ip"`}
        />
        <CopyField
          label="Sample Proxy Code"
          value={`curl -x "http://scraperapi:${apiKey}@proxy-server.scraperapi.com:8001" -k "http://httpbin.org/ip"`}
        />
      </div>
      <div className="flex items-center justify-start md:justify-end mt-8 gap-x-10">
        <ExtLink
          href="https://www.scraperapi.com/documentation"
          className="text-sm underline uppercase text-brandDarkest underline-offset-2"
        >
          <>View Documentation</>
        </ExtLink>
      </div>
    </div>
  );
}
