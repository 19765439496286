import { Title } from "react-head";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import scraperApi from "api";

import Button from "components/Button";
import Form from "components/Form";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";
import TaggedText from "components/TaggedText";
import Toaster from "components/Toaster";
import QuestionsPage from "components/onboarding/OnboardingPage/QuestionsPage";
import { pageData } from "components/onboarding/OnboardingPage/Pages";

import { ReactComponent as GMailIcon } from "assets/icons/gmail-icon.svg";
import { ReactComponent as OutlookIcon } from "assets/icons/outlook-icon.svg";

import { useUser } from "routes/dataroutes/UserData";


export function SendActivationEmailOld() {
  const user = useUser();
  const navigate = useNavigate();
  const methods = useForm();
  const {
    formState: { isSubmitSuccessful }
  } = methods;
  const [ error, setError ] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    if (!user?.email) {
      return setError(
        "Your email was not found. Please contact support for help."
      );
    }
    try {
      await scraperApi.auth.sendActivationEmail(user.email);
    } catch (err) {
      Toaster.error(
        "Problem with email activation",
        <>We were not able to send you the activation email. Please try again or please send an email to <a href="mailto:support@scraperapi.com" className="text-brandPrimary hover:underline">support@scraperapi.com</a>.</>
      );
    }

  }, [ user ]);

  if (isSubmitSuccessful) {
    return (
      <div className="space-y-8 text-center">
        <Title>ScraperAPI | Send Activation Email</Title>
        <div className="space-y-2">
          <div className="text-2xl text-brandDarkest">
            Activation email sent
          </div>
          <p className="text-sm text-gray">
            Please check your <span className="font-bold">{ user?.email }</span>{ " " }
            inbox for more instructions
          </p>
        </div>
      </div>
    );
  }

  return (
    <Form onSubmit={ onSubmit } methods={ methods }>
      <Title>ScraperAPI | Send Activation Email</Title>
      <div className="space-y-8 text-center">
        <div className="space-y-2">
          <div className="text-2xl text-brandDarkest">
            Activate your account
          </div>
          <div className="text-sm text-gray">
            Start using your account by validating your email address.{ " " }
            <div className="font-bold">{ user?.email }</div>
          </div>
          { error && <div className="text-sm text-red">{ error }</div> }
        </div>
        <div className="space-y-2">
          <Button
            text="Send Activation Email"
            onClick={ () => {} }
            type="submit"
            Icon={ FormSubmittingSpinner }
            size="LG"
          />
          <div
            className="underline transition-colors cursor-pointer text-gray hover:text-gray-800"
            onClick={ async () => {
              await scraperApi.auth.logout();
              navigate("/login");
            } }
          >
            Log out
          </div>
        </div>
      </div>
    </Form>
  );
}

export function SendActivationEmailNew() {
  const user = useUser();

  const methods = useForm();

  const [ isEmailSent, setEmailSent ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    if (!user?.email) {
      return setError("Your email was not found. Please contact support for help.");
    }

    try {
      await scraperApi.auth.sendActivationEmail(user.email);
      setEmailSent(true);
    } catch (err) {
      Toaster.error(
        "Problem with email activation",
        <>We were not able to send you the activation email. Please try again or please send an email to <a href="mailto:support@scraperapi.com" className="text-brandPrimary hover:underline">support@scraperapi.com</a>.</>
      );
    }
  }, [ user ]);

  if (isEmailSent) {
    return (
      <div className="flex flex-col items-start self-stretch gap-12">
        <QuestionsPage page={ {
          ...pageData[0],
          title: "Check your email",
          description: <>We've sent an email to <b>{ user!.email }</b> with a link to activate your account.</>
        } }
        />

        <div className="flex flex-row gap-x-6">
          <Button
            text="Open Gmail"
            Icon={ GMailIcon }
            theme="onboarding_option"
            size="LG"
            href="https://mail.google.com/"
          />
          <Button
            text="Open Outlook"
            Icon={ OutlookIcon }
            theme="onboarding_option"
            size="LG"
            href="https://outlook.live.com/mail/"
          />
        </div>

        <div className="text-gray">
          Can't find the email? Check your spam or <span className="underline cursor-pointer" onClick={ () => {
          setEmailSent(false);
        } }>Resend activation email</span>
        </div>
      </div>
    );
  } else {
    return (
      <Form onSubmit={ onSubmit } methods={ methods }>
        <div className="flex flex-col items-start self-stretch gap-12">
          <QuestionsPage page={
            {
              ...pageData[0],
              description: <>To complete the signup, click the button below and we'll send a verification email to { user?.email ? <b>{ user.email }</b> : "your inbox" }</>
            }
          } />

          { error &&
              <div className="text-sm text-red">
                { error }
              </div>
          }

          <Button
            text="Send Activation Email"
            onClick={ () => {} }
            type="submit"
            Icon={ FormSubmittingSpinner }
            size="LG"
          />

          <div className="flex flex-col items-start space-y-2 text-base text-gray">
            <div>
              <Link
                to="/login"
                onClick={ async () => { await scraperApi.auth.logout() } }
                className="underline transition-colors text-gray hover:text-gray-800"
              >
                Log in
              </Link>
              {" "}with a different account.
            </div>
            <TaggedText message="Having trouble activating your account? Contact [support@scraperapi.com|contact_support_email]." />
          </div>
        </div>
      </Form>
    );

  }
}
