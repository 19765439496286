import { Link } from "react-router-dom";

export default function StartNowLinks() {
  return (
    <div className="flex flex-col md:flex-row gap-8 text-lightGray underline pt-20">
      <Link
        to="https://www.scraperapi.com/documentation/"
        target="_blank"
      >
        Go to documentation
      </Link>
      <Link
        to="/billing"
      >
        Go to pricing page
      </Link>
      <Link
        to="/contact-sales"
      >
        Get in touch with the sales team
      </Link>
    </div>
  );
};
