import { CircledNumber } from "components/CircledNumber";

export const SectionTitle = ({number, title}: {number: number, title: string}) => (
  <div className="flex flex-row gap-2 items-center mt-4 mb-11 shrink-0 grow-0">
    <CircledNumber number={number} />
    <p className="text-xl text-brandDarkest font-medium">
      {title}
    </p>
  </div>
);
