import { Title } from "react-head";
import { useLocation } from "react-router-dom";

export default function MagicLink() {
  const location = useLocation();
  const state = location?.state as { email?: string };

  return (
    <div className="flex flex-col mb-8 space-y-2">
      <Title>ScraperAPI | Email Sent</Title>

      <div className="text-2xl text-brandDarkest">Check your inbox</div>
      <div className="text-base text-gray">
        An email has been sent to {state?.email || "your email"} with a link to
        login
      </div>
    </div>
  );
}
