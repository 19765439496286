import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import { ProjectBreadcrumbPage } from "components/ProjectBreadcrumbPage";
import SemiCircleChart from "components/SemiCircleChart";
import { projectConfigToShow }  from "components/hosted-scraping/showProjectConfig";

import scraperApi from "api";

import { useHostedScrapingProjects } from "providers/HostedScrapingProvider";
import { Navigate, useParams } from "react-router-dom";
import { ProjectStatus } from "components/hosted-scraping/ProjectStatus";
import { JobList } from "components/hosted-scraping/JobList";

import { ReactComponent as ProjectStatusIcon } from "assets/icons/project-details-status-satellite-dish.svg";
import { ReactComponent as CreditsSpentIcon } from "assets/icons/project-details-credits-spent.svg";
import { ReactComponent as CostPerRequestIcon } from "assets/icons/project-details-cost-per-request.svg";
import { ReactComponent as CalendarLastRunIcon } from "assets/icons/project-details-calendar-last-run.svg";
import { ReactComponent as CalendarCreatedIcon } from "assets/icons/project-details-calendar-created.svg";

import { ReactComponent as ProjectDetailsEqualizer } from "assets/icons/project-details-config-equalizer.svg";
import { ReactComponent as ProjectDetailsLink } from "assets/icons/project-details-config-link.svg";
import { ReactComponent as ProjectDetailsRoundThingy } from "assets/icons/project-details-config-roundes-thingy.svg";

import { PlayIcon } from  "@heroicons/react/outline"
import { Tab } from "@headlessui/react";

import { formatDate } from "components/hosted-scraping/ProjectFormatDate";
import { ProjectConfigWithStats } from "providers/HostedScrapingProvider/types";
import { formatCreditsSpent } from "components/hosted-scraping/creditsSpent";

import { ProjectDetailsHead } from "components/hosted-scraping/ProjectDetailsHead";
import { ProjectNotFound } from "components/hosted-scraping/ProjectNotFound";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";
import Toaster from "components/Toaster";
import { tooltips } from "components/hosted-scraping/edit-project-components/tooltips";
import { PureTooltip, Tooltip } from "components/Tooltip";

import BorderLayout from "layouts/BorderLayout";

import { useCustomerIO } from "providers/CustomerIOProvider";
import { followRedirectToDisableFollowRedirect } from "providers/HostedScrapingProvider/negateFollowRedirects";

import { cx, toNumber, compareDates } from "utils";
import { isAmazonProject, isAsyncUrlsProject, isGoogleProject, isWalmartProject } from "sdecontent";

const formatSupposedToRunAt = (scheduledAt: Date | null | string | undefined): string => {
  const now = new Date();
  const schAt = new Date(scheduledAt || Date.now());
  if (!Boolean(scheduledAt) || schAt > now) {
    return formatDate(scheduledAt);
  } else {
    return 'Any time now'
  }
}

const SuccessAndFailureRateChart = ({project}: {project: ProjectConfigWithStats}) => {
  //const finishedJobs = project.doneJobsCount + project.errorJobsCount;
  const finishedTasks = project.successfulTasks + project.failedTasks;
  const doneRate = finishedTasks >= 1 ? ((project.successfulTasks / finishedTasks) * 100.0).toFixed(0) : '-';
  const errorRate = finishedTasks >= 1 ?((project.failedTasks / finishedTasks) * 100.0).toFixed(0) : '-';
  const doneLabel = `Success (${doneRate}%)`;
  const errorLabel = `Failure (${errorRate}%)`;

  return (<div className="w-full flex flex-row">
    <div>
      <SemiCircleChart success={project.successfulTasks} failure={project.failedTasks} circumference={360} legend={[doneLabel, errorLabel]} legendPosition="right"/>
    </div>
  </div>);
};

const creditsPerFinishedJob = (project: ProjectConfigWithStats): number | undefined => {
  const { credits, doneJobsCount, errorJobsCount } = project;
  const finishedJobs = doneJobsCount + errorJobsCount;

  if (finishedJobs === 0 || finishedJobs === undefined || finishedJobs === null) {
    return undefined;
  }

  return credits / finishedJobs;
}

const generalStatusLineStyle = "flex flex-row items-center justify-between w-96 text-sm font-normal";
const generalStatsLeftSideStyle = "w-40 text-gray justify-self-start";
const generalStatsRightSideStyle = "w-1/2";
const GeneralStats = ({project}: {project: ProjectConfigWithStats}) => (
  <div className="w-full h-full grid font-normal text-base projectStatsBreak:grid-rows-3 projectStatsBreak:grid-cols-2 grid-rows-6 grid-cols-1 grid-flow-col justify-items-start p-4">
    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center">
        <div className="mr-2 ml-2"><ProjectStatusIcon/></div>
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsStatus}>Status</Tooltip></div>
      </div>
      <div className={generalStatsRightSideStyle}>
        <ProjectStatus project={project}/>
      </div>
    </div>
    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center">
        <div className="mr-2 ml-2"><CalendarCreatedIcon/></div>
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsCreated}>Created</Tooltip></div>
      </div>
      <div className="whitespace-nowrap w-1/2">
        {formatDate(project.createdAt)}
      </div>
    </div>

    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center">
        <div className="mr-2 ml-2"><CalendarLastRunIcon/></div>
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsScheduled}> Scheduled</Tooltip></div>
      </div>
      <div className="whitespace-nowrap w-1/2">
        {formatSupposedToRunAt(project.supposedToRunAt)}
      </div>
    </div>

    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center">
        <div className="mr-2 ml-2"><CostPerRequestIcon/></div>
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsCostPerJob}>Cost per job</Tooltip></div>
      </div>
      <div className="whitespace-nowrap w-1/2">{formatCreditsSpent(creditsPerFinishedJob(project))}</div>
    </div>

    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center">
        <div className="mr-2 ml-2"><CreditsSpentIcon/></div>
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsCreditsSpent}>Credits spent</Tooltip></div>
      </div>
      <div className="whitespace-nowrap w-1/2">{formatCreditsSpent(project.credits)}</div>
    </div>

    <div className={generalStatusLineStyle}>
      <div className="flex flex-row items-center max-w-[11rem]">
        <div className="mr-2 ml-2"><CreditsSpentIcon/></div>
        {/* Note the <br/> on the next line. It's a controlled break, the layout would break the text anyway but the width of the element would be the maximum so the (?) on right side would look ugly */}
        <div className={generalStatsLeftSideStyle}><Tooltip content={tooltips.projectsCreditsSpentInBillingPeriod}>Credits spent in<br/>billing period</Tooltip></div>
      </div>
      <div className="whitespace-nowrap w-1/2">{formatCreditsSpent(project.creditsInBillingPeriod)}</div>
    </div>
  </div>
);

const Block = ({children}: { children: ReactNode }) => {
  return (
      <div className="w-full border border-slate-200 p-2 bg-white overflow-auto">
        {children}
      </div>
  );
};

const TabButton = ({ children }: { children: ReactNode }) => {
  return (
    <Tab>
      {({ selected }) => (
        <div className={cx("px-2 pb-2 border-b-2", selected ? "border-brandPrimary text-brandDarkest" : "border-transparent")}>
          {children}
        </div>
      )}
    </Tab>
  );
};

const BasicInfoAndStats = ({project}: {project: ProjectConfigWithStats}) => {
  return (
    <div className="flex flex-row flex-wrap p-4">
    <div className="">
      <SuccessAndFailureRateChart project={project}/>
    </div>
    <div className="">
      <GeneralStats project={project}/>
    </div>
    </div>
  );
};

const ConfigurationDetailLine = ({Icon, title, children}: {Icon: any, title: string, children: ReactNode}) => (
  <div className="flex flex-row gap-1 items-start">
    <div className= "w-1/2 flex flex-row items-center">
      <div className='mr-2'><Icon/></div>
      <div className="text-sm text-gray-600 pr-2">{title}</div>
    </div>
    <div className="w-full text-sm">{children}</div>
  </div>
)

const icons = {
  'round-thingy': ProjectDetailsRoundThingy,
  'link': ProjectDetailsLink,
  'equalizer': ProjectDetailsEqualizer
};

const UrlProjectAsyncApiParams = ({project}: {project: ProjectConfigWithStats}) => {
  return (<>
    {project.config?.apiParams?.premium && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Premium residential and mobile IPs">Enabled</ConfigurationDetailLine>}
    {project.config?.apiParams?.ultraPremium && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Advanced bypass mechanism">Enabled</ConfigurationDetailLine>}
    {followRedirectToDisableFollowRedirect(project.config?.apiParams?.followRedirect as boolean) && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Follow redirects">Disabled</ConfigurationDetailLine>}
    {/* <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Keep headers">
      {project.config?.apiParams?.keepHeaders ? 'Enabled' : 'Disabled'}
    </ConfigurationDetailLine> */}
    {project.config?.apiParams?.render && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Javascript rendering">Enabled</ConfigurationDetailLine>}
    {project.config?.apiParams?.waitForSelector && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Wait for selector">{project.config?.apiParams?.waitForSelector as string}</ConfigurationDetailLine>}
    {project.config?.apiParams?.retry404 && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Retry 404">Enabled</ConfigurationDetailLine>}
    {project.config?.apiParams?.autoparse && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Auto parse">Enabled</ConfigurationDetailLine>}
    {project.config?.apiParams?.countryCode && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Country Code">{project.config?.apiParams?.countryCode as string}</ConfigurationDetailLine>}
    {project.config?.apiParams?.deviceType && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Device Type">{project.config?.apiParams?.deviceType as string}</ConfigurationDetailLine>}
  </>);
}

const AmazonProjectAsyncApiParams = ({project}: {project: ProjectConfigWithStats}) => {
  const params = (project.config || {});
  return (<>
    {params.apiParams?.countryCode && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Country Code">{params.apiParams?.countryCode as string}</ConfigurationDetailLine>}
    {params.apiParams?.tld && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Domain">{params.apiParams?.tld as string}</ConfigurationDetailLine>}
  </>);
}

const GoogleProjectAsyncApiParams = ({project}: {project: ProjectConfigWithStats}) => {
  const params = (project.config || {});
  return (<>
    {params.apiParams?.countryCode && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Country Code">{params.apiParams?.countryCode as string}</ConfigurationDetailLine>}
    {params.apiParams?.tld && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Domain">{params.apiParams?.tld as string}</ConfigurationDetailLine>}
    {params.apiParams?.uule && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="UULE">{params.apiParams?.uule as string}</ConfigurationDetailLine>}
    {params.apiParams?.num && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Number of results">{String(params.apiParams?.num)}</ConfigurationDetailLine>}
    {params.apiParams?.additionalParams && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Additional parameters">{params.apiParams?.additionalParams as string}</ConfigurationDetailLine>}
  </>);
}

const WalmartProjectAsyncApiParams = ({project}: {project: ProjectConfigWithStats}) => {
  const params = (project.config || {});
  return (<>
    {params.apiParams?.tld && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Domain">{params.apiParams?.tld as string}</ConfigurationDetailLine>}
    {params.apiParams?.page && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Page">{String(params.apiParams?.page)}</ConfigurationDetailLine>}
    {params.apiParams?.sort && <ConfigurationDetailLine Icon={ProjectDetailsEqualizer} title="Sort by">{String(params.apiParams?.sort)}</ConfigurationDetailLine>}
  </>);
}

const sectionHeaderStyle = "font-semibold text-base text-gray-600 mb-2 mt-2";
const ConfigurationDetails = ({project}: {project: ProjectConfigWithStats}) => {
  const configView = projectConfigToShow(project);
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 p-4 gap-x-8">
    <div>
      <div className={sectionHeaderStyle}>Input</div>
      <div className="flex flex-col gap-2">
        {configView.input.map( (entry, idx) => (
          <ConfigurationDetailLine key={idx} Icon={icons[entry.icon]} title={entry.title}>
            {entry.component}
          </ConfigurationDetailLine>
        ))}
      </div>
    </div>
    <div>
      <div className={sectionHeaderStyle}>Output</div>
      <div className="flex flex-col gap-2">
        {configView.output.map( (entry, idx) => (
          <ConfigurationDetailLine key={idx} Icon={icons[entry.icon]} title={entry.title}>
            {entry.component}
          </ConfigurationDetailLine>
        ))}
        {configView.interval && (
          <ConfigurationDetailLine Icon={icons[configView.interval.icon]} title={configView.interval.title}>
            {configView.interval.component}
          </ConfigurationDetailLine>
        )}
      </div>
    </div>
    <div>
      <div className={sectionHeaderStyle}>Additional options &amp; filters</div>
      <div className="flex flex-col gap-2">
          {isAsyncUrlsProject(project.config.type) && <UrlProjectAsyncApiParams project={project}/>}
          {isAmazonProject(project.config.type) && <AmazonProjectAsyncApiParams project={project}/>}
          {isGoogleProject(project.config.type) && <GoogleProjectAsyncApiParams project={project}/>}
          {isWalmartProject(project.config.type) && <WalmartProjectAsyncApiParams project={project}/>}
      </div>
    </div>
    </div>
  );
};

export default function ProjectDetails() {
  const { projects, refreshInBackground, refreshProject, inProgress } = useHostedScrapingProjects();
  const { projectId: projectIdParam} = useParams() as { projectId: string};
  const [ projectShouldRunSoon, setProjectShouldRunSoon ] = useState(true);
  const customerIO = useCustomerIO();

  const projectId = parseInt(projectIdParam);

  const scheduleNow = useCallback(async() => {
    if (!projectShouldRunSoon) {
      customerIO.runJobNowButtonPressed(projectId);
      setProjectShouldRunSoon(true);
      await scraperApi.hostedScraping.scheduleNowProject(projectId);
      Toaster.success("New job successfully scheduled", "A new job will start in a few seconds. The started job will be visible in the list below.");
      refreshProject(projectId, new AbortController()); // TODO: do the abortcontroller properly
    }
  }, [projectId, refreshProject, projectShouldRunSoon, customerIO]);

  // Refresh he projects
  useEffect(() => {
    const controller = new AbortController();

    const timer = setInterval(() => refreshInBackground(controller), 10_000);

    return () => {
      controller.abort();
      clearInterval(timer);
    }
  }, [refreshInBackground, projectId]);

  const project = useMemo(() => {
      return projects?.find(p => p.id === projectId);
    },
    [ projects, projectId ]
  );

  const num = (val: string | number | undefined) => {
    return toNumber(val) || 0;
  };

  useEffect(() => {
      if (project && !inProgress) {
        if (
          (project?.supposedToRunAt && compareDates(project.supposedToRunAt, Date.now()) <= 0) ||  // project is scheduled to run immediately but hasn't been picked up by a worker yet
          (project && num(project.allJobsCount) > (num(project.doneJobsCount) + num(project.errorJobsCount) + num(project.cancelledJobsCount)))  // project has unfinished jobs
        ) {
          setProjectShouldRunSoon(true);
        } else {
          setProjectShouldRunSoon(false);
        }
      }
    },
    [ inProgress, project, setProjectShouldRunSoon ]
  );


  if (projectIdParam === 'demo' && projects && !inProgress) {
    const demoProject = projects.find(p => p.demoProject);
    if (demoProject === undefined) {
      return (<ProjectNotFound/>);
    } else {
      return <Navigate to={`/projects/${demoProject.id}`} replace={true} />;
    }
  }

  if (project === undefined && !inProgress) {
    return (<ProjectNotFound/>);
  }

  if (project === undefined) {
    return <FullWidthLoadingSpinner/>;
  }

  const runNowButton = (
    <Button
      Icon={ PlayIcon }
      text="Run job now"
      theme={ projectShouldRunSoon ? "disabled" : "highlighted" }
      size="MD"
      onClick={ scheduleNow }
    />
  );

  const titleButtons = (
    <div className="flex gap-2 mr-2">
      { projectShouldRunSoon && (
        <PureTooltip content={tooltips.runNowDisabled}>
          { runNowButton }
        </PureTooltip>
      )}
      { !projectShouldRunSoon && runNowButton }
      <Button
        text="Edit"
        theme="default"
        href={ `/projects/${ projectId }/edit` }
        size="MD"
        className="h-full" // To follow the size of Run job now
      />
    </div>);

  // TODO: The background should be bg-gray-50 (249, 250, 251) but it's way darker than it should be
  return (
    <BorderLayout layout="vertical">
      <ProjectBreadcrumbPage readonly titleButtons={titleButtons} className="bg-slate-50" projectName={project.name} projectId={project.id}>
        <div className="flex flex-col gap-5 md:px-14 pt-10 pb-24 overflow-auto">
          <ProjectDetailsHead variant="hosted-scraper" configType={project.config.type}/>
          <div className="w-full border-t border-slate-200"/>

          <Block>
            <Tab.Group>
              <Tab.List className="text-gray m-4 flex gap-x-6 border-b-[1px] border-slate-200">
                <TabButton>Overview</TabButton>
                <TabButton>Configuration</TabButton>
              </Tab.List>
              <Tab.Panels className="m-4">
                <Tab.Panel><BasicInfoAndStats project={project}/></Tab.Panel>
                <Tab.Panel><ConfigurationDetails project={project}/></Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Block>

          <JobList supposedToRunAt={project.supposedToRunAt} projectId={project.id}/>
        </div>
      </ProjectBreadcrumbPage>
    </BorderLayout>
  );
}
