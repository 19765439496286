import { useCallback, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useParams, useRevalidator } from "react-router-dom";

import Button from "components/Button";
import CouponCodeInput from "components/CouponCodeInput";
import Form from "components/Form";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";

import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";

import Modal, {
  getTotalPriceToInvoice,
  isCouponError,
  ModalButtonsSection,
  ModalContent,
  ModalSections,
  useCloseModal
} from "./index";
import { ErrorMessage } from "./ErrorMessage";

import scraperApi from "api";
import { fmtCurrency } from "utils";
import { isAnnualPlan } from "utils/planUtils";


export default function OldUpgradeSubscriptionModal() {
  let { planId } = useParams() as { planId: string };

  const methods = useForm();
  const { setError } = methods;
  const [ error, setFormError ] = useState<string>();
  const revalidator = useRevalidator();

  const user = useUser();
  const { subscription, plans, refresh } = useUserProvider();
  const targetPlan = plans?.find(p => p.id === planId);
  const price = getTotalPriceToInvoice(targetPlan, subscription);

  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const couponDetails = subscription?.couponDetails;
      if (couponDetails?.find(c => c.coupon_code === data.couponCode)) {
        return setError("coupon", {
          type: "custom",
          message: "That coupon has already been used"
        });
      }

      try {
        await scraperApi.subscription.update(planId, true, [ data.couponCode ], false);
        revalidator.revalidate();
        await refresh();
        goBack();
      } catch (error) {
        const err = error as any;
        if (isCouponError(err)) {
          setError("coupon", {
            type: "custom",
            message: err.details?.message || err.message
          });
        } else {
          setFormError(err.message || "There was an error upgrading your subscription");
        }
      }
    },
    [goBack, refresh, revalidator, planId, setError, subscription?.couponDetails]
  );

  const closeModal = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });


  return (
    <Modal headline="Upgrade Subscription" onClose={ closeModal } >
      <Form methods={ methods } onSubmit={ onSubmit }>
        <ModalContent>
          <ModalSections>
            <div>
              Are you sure you want to upgrade your subscription to{ " " }
              <span className="font-bold capitalize">
                { targetPlan?.name.replace("202206", "").trim() }
              </span>
              ?
            </div>
            <div>
              By upgrading your subscription, we will reset your credit
              counters, Invoice{ " " }
              <span className="font-bold">
                { fmtCurrency(price / 100) }{ " " }
              </span>{ " " }
              now and set your billing date to today.
            </div>
            { (user?.canUseAllCoupons || (user?.canUseCoupons && !isAnnualPlan(planId))) && (
              <CouponCodeInput
                couponDetails={ subscription?.couponDetails }
                targetPlanSlug={ planId }
              />
            ) }
            { ((user?.canUseAllCoupons === false) && isAnnualPlan(planId) && ((subscription?.couponDetails?.length || 0) > 0)) && (
              <div>
                Your current extra discount coupons will also be removed after the upgrade.
              </div>
            ) }
            <ErrorMessage errorMessage={ error } closeModalCallback={ goBack }/>
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Cancel" theme="default" onClick={ goBack } size="MD"/>
            <Button
              text="Upgrade Subscription"
              theme="highlighted"
              type="submit"
              Icon={ FormSubmittingSpinner }
              onClick={ () => {
                setFormError(undefined);
              } }
              size="MD"
            />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
};
