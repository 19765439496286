import _ from "lodash";


export const cx = (...classes: any): string => {
  return classes.filter(Boolean).join(" ");
};

export const fmtDate = (date: Date) => {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(date);
};

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const fmtCurrency = (amount?: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(amount || 0);
};

export const fmtNumber = (amount?: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0
  }).format(amount || 0);
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const join = (arr: Array<any>, separator: string, lastSeparator?: string) => {
  if (lastSeparator && (arr.length > 1)) {
    return `${arr.slice(0, -1).join(separator)}${lastSeparator}${_.last(arr)}`;
  } else {
    return `${arr.join(separator)}`;
  }
};

export const toNumber = (value: string | number | undefined) => {
  if (typeof value === "string") {
    return Number.parseInt(value, 10);
  } else {
    return value;
  }
};

export const compareDates = (date1: Date | string | number | undefined, date2: Date | string | number | undefined): number => {
  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getTime() - d2.getTime();
};
