import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import DecoratedInputField from "./DecoratedInputField";
import InputFieldLabel from "./InputFieldLabel";

import { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";

import { cx } from "utils";


const InputFieldComponents = forwardRef((
  {
    label,
    labelPosition = "top",
    isValidated = false,
    requiredErrorMessage = "This field is required.",
    fullWidth = false,
    typeMismatchErrorMessage = "Please enter a valid value.",
    tooLongErrorMessage = "Value is too long.",
    tooShortErrorMessage = "Value is too short.",
    patternMismatchErrorMessage = "Value is not properly formatted.",
    TrailingElement,
    inputPaddingForTrailingElement = "pr-10",
    errorMessage,
    successMessage,
    formatValue,
    unformatValue,
    unformattedInputRef,
    relative,
    onFocusLost,
    ...props
  }: IInputFieldWithValidationProps,
  ref: ForwardedRef<HTMLInputElement | null>
) => {
  const [ validationErrorMessage, setValidationErrorMessage ] = useState<string | JSX.Element>(<>&nbsp;</>);
  const [ showValidationError, setShowValidationError ] = useState(false);
  const [ initialTrackedValue ] = props.value && formatValue ? formatValue(props.value.toString()) : [ props.value || "" ];
  const [ trackedValue, setTrackedValue ] = useState(initialTrackedValue);
  const [ cursorPosition, setCursorPosition ] = useState<number | null>();

  const setProperErrorMessage = useCallback((htmlInput: HTMLInputElement) => {
    const validityState = htmlInput.validity;
    if (validityState.customError) {
      return setValidationErrorMessage(htmlInput.validationMessage);
    }
    if (validityState.typeMismatch) {
      return setValidationErrorMessage(typeMismatchErrorMessage);
    }
    if (validityState.valueMissing) {
      return setValidationErrorMessage(requiredErrorMessage);
    }
    if (validityState.tooLong) {
      return setValidationErrorMessage(tooLongErrorMessage);
    }
    if (validityState.tooShort) {
      return setValidationErrorMessage(tooShortErrorMessage);
    }
    if (validityState.patternMismatch) {
      return setValidationErrorMessage(patternMismatchErrorMessage);
    }

    setValidationErrorMessage(<>&nbsp;</>);
  }, [ setValidationErrorMessage, typeMismatchErrorMessage, requiredErrorMessage, tooLongErrorMessage, tooShortErrorMessage, patternMismatchErrorMessage ]);

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!, []);

  useEffect(() => {
    if (inputRef.current) {
      if (errorMessage !== undefined) {
        inputRef.current.setCustomValidity(errorMessage);
        setValidationErrorMessage(errorMessage);
      } else if (successMessage !== undefined) {
        inputRef.current.setCustomValidity("");
        setValidationErrorMessage("");
      } else {
        setProperErrorMessage(inputRef.current);
      }
      inputRef.current.form?.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [ isValidated, errorMessage, successMessage, setProperErrorMessage, trackedValue ]);

  useEffect(() => {
    if (cursorPosition && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [ trackedValue, cursorPosition, inputRef ]);

  useEffect(() => {
    setTrackedValue(initialTrackedValue);
  }, [ initialTrackedValue ])


  return (
    <>
      <InputFieldLabel
        htmlFor={ props.id }
        className={ cx(
          labelPosition === "right" && "order-2",
          labelPosition === "bottom" && "order-2",
          (props.type === "checkbox") && (labelPosition === "left" || labelPosition === "right") && "-mt-1"
        ) }
      >
        { label }
      </InputFieldLabel>
      <div className="group">
        <DecoratedInputField
          { ...props }
          ref={ inputRef }
          value={ trackedValue }
          className={ cx(
            (showValidationError || isValidated) && "invalid:text-red placeholder:invalid:text-red-400 invalid:border-red focus:invalid:ring-red",
            labelPosition === "right" && "order-1",
            labelPosition === "bottom" && "order-1",
            relative && "relative"
          ) }
          TrailingElement={ TrailingElement }
          inputPaddingForTrailingElement={ inputPaddingForTrailingElement }

          onChange={ (event: any) => {
            props.onChange?.(event);

            if (formatValue && unformatValue) {
              const [ unformattedValue, cursorPosition ] = unformatValue(event.currentTarget.value, event.currentTarget.selectionStart || 0);
              if (unformattedInputRef?.current && unformattedValue) {
                unformattedInputRef.current.value = unformattedValue;
              }
              const [ formattedValue, newCursorPosition ] = formatValue(unformattedValue, cursorPosition);

              setTrackedValue(formattedValue);
              setCursorPosition(newCursorPosition);
            } else {
              setTrackedValue(event.currentTarget.value);
              setCursorPosition(event.currentTarget.selectionStart);
            }

            setShowValidationError(true);
            props.onChange?.(event);
            setProperErrorMessage(event.currentTarget);
          } }
          onBlur={ (event: any) => {
            setShowValidationError(true);
            props.onBlur?.(event);
            onFocusLost?.(event, setTrackedValue);
            setProperErrorMessage(event.currentTarget);
          } }
        />

        <div className={ cx(
          "text-sm col-span-2",
          validationErrorMessage && "text-red invisible",
          successMessage && "text-gray",
          (showValidationError || isValidated) && "group-has-[:invalid]:visible", // TODO this makes the error message visible even if the parent (the disclosure) is hidden
          labelPosition === "right" && "order-3",
          labelPosition === "bottom" && "order-3"
        ) }
        >
          { validationErrorMessage || successMessage }
        </div>
      </div>
    </>
  );
});


export default InputFieldComponents;
