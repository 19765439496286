import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Transition } from "@headlessui/react";
import { RiCoinsLine, RiSideBarLine } from "@remixicon/react";

import scraperApi from "api";
import { cx } from "utils";
import { getUserInitials } from "utils/userUtils";

import { ReactComponent as MobileMenuIcon } from "assets/icons/menu-icon.svg";
import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";
import { ReactComponent as SLogo } from "assets/icons/scraperapi-logo-no-text.svg";
import { ReactComponent as ScraperAPI } from "assets/icons/scraperapi-logo-no-icon.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard-icon.svg";
import { ReactComponent as BillingIcon } from "assets/icons/billing-icon.svg";
import { ReactComponent as ScrapingIllustration } from "assets/images/scraperapi-illustration2.svg";
import { ReactComponent as PulseIcon } from "assets/icons/pulse-icon.svg";
import { ReactComponent as LifebuoyIcon } from "assets/icons/lifebuoy-icon.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document-icon.svg";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset-icon.svg";
import { ReactComponent as ApiPlaygroundIcon } from "assets/icons/api-playground.svg";
import { ReactComponent as DataPipelineIcon } from "assets/icons/data-pipeline.svg";


import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";
import { UserBadge, UserMenuButtonControlled, UserMenuControlled } from "components/UserMenu";
import TaggedText from "components/TaggedText";

import { useLocalStorage } from "hooks/useLocalStorage";
import { useSupportWidget } from "hooks/useSupportWidget";

import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";



const PANEL_WIDTH = "w-[300px]";
const COLLAPSED_WIDTH = "w-[74px]";
const COLLAPSED_TRANSLATE = "translate-x-[221px]";  // 300 - 79px
const COLLAPSED_PANEL_TRANSLATE = "-translate-x-[221px]";
const USER_MENU_WIDTH = "w-[270px]";


interface ISidebarItemProps {
  Icon?: React.ComponentType<any>;
  iconStyle?: string;
  icon?: JSX.Element;
  content?: JSX.Element | string;
  contentPadding?: string;
  collapsed?: boolean;
  disabled?: boolean;
  disableWhenBlocked?: boolean;
  active?: boolean;
  onClick?: () => void;
  featureSwitch?: FeatureSwitch;
}

function SidebarItemContent({ Icon, iconStyle, icon, content, contentPadding = "py-3 pr-6", collapsed, active }: ISidebarItemProps) {
  return (
    <>
      { (Icon || icon) && (
        <div
          className={cx(
            "z-20 pl-6 py-3 border-l-[3px]",
            collapsed && `${ COLLAPSED_TRANSLATE } group-hover:translate-x-0 transition-transform ease-in-out duration-500`,
            active ? "border-l-brandPrimary" : "border-l-transparent"
          ) }
        >
          { Icon && <Icon className={ iconStyle } /> }
          { icon }
        </div>
      ) }
      <div className={ cx(
        "w-full",
        contentPadding,
        collapsed && "*:transition-opacity *:opacity-0 *:group-hover:opacity-100 *:duration-700"
        ) }
      >
        { typeof content === "string" ? <div className="font-droid pl-4 w-full">{ content }</div> : <>{ content }</> }
      </div>
    </>
  );
}

function SidebarItem({ Icon, iconStyle, icon, content, contentPadding, collapsed }: ISidebarItemProps) {
  return (
    <div className="flex flex-row items-center w-full *:bg-white">
      <SidebarItemContent
        Icon={ Icon }
        iconStyle={ iconStyle }
        icon={ icon }
        content={ content }
        contentPadding={ contentPadding }
        collapsed={ collapsed }
      />
    </div>
  );
}

function useReallyDisabled(disabled?: boolean, disableWhenBlocked?: boolean) {
  const user = useUser();
  return disabled || (disableWhenBlocked && user?.isBlocked);
}

function SidebarNavLink({ disabled, disableWhenBlocked, to, onClick, addLocationToState, featureSwitch, ...props }: ISidebarItemProps & { to: string, addLocationToState?: boolean }) {
  const location = useLocation();
  const isFeatureEnabled = useFeatureSwitch(featureSwitch);
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  if (!isFeatureEnabled) {
    return <></>;
  }

  return (
    <NavLink
      to={ to }
      className={ ({ isActive }) => cx(
        "flex flex-row items-center w-full *:bg-white",
        !reallyDisabled && "*:hover:bg-slate-50 hover:bg-slate-50",
        (!reallyDisabled && isActive) ? "text-brandPrimary" : "*:hover:text-gray-700 hover:text-gray-700"
      ) }
      onClick={ onClick }
      state={ { backgroundLocation: addLocationToState ? location : undefined } }
    >
      { ({ isActive }) => (
        <SidebarItemContent
          { ...props }
          active={ isActive }
        />
      )}
    </NavLink>
  );
}

function SidebarExtLink({ disabled, disableWhenBlocked, href, onClick, ...props }: ISidebarItemProps & { href: string }) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={ href }
      className={ cx(
        "flex flex-row items-center w-full *:bg-white",
        !reallyDisabled && "*:hover:bg-slate-50 hover:bg-slate-50 *:hover:text-gray-700 hover:text-gray-700"
      )}
      onClick={ onClick }
    >
      <SidebarItemContent
        { ...props }
      />
    </a>
  )
}

function SidebarAction({ disabled, disableWhenBlocked, onClick, ...props }: ISidebarItemProps) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <div
      className={ cx(
        "flex flex-row items-center w-full *:bg-white cursor-pointer",
        !reallyDisabled && "*:hover:bg-slate-50 hover:bg-slate-50 *:hover:text-gray-700 hover:text-gray-700"
      ) }
      onClick={ onClick }
    >
      <SidebarItemContent { ...props } />
    </div>
  );
}

const UpgradeButton = ({ onClickCallback }: { onClickCallback?: () => void }) => {

  const showNewSalesBanner = useFeatureSwitch("REACT_APP_NEW_SALES_FORM_USERS");
  const { subscription } = useUserProvider();

  if (subscription?.plan_id !== "free") {
    return <></>;
  }

  if (showNewSalesBanner) {
    return (
      <div className="flex flex-col items-center justify-center ml-6 my-4 p-4 space-y-4 bg-brandLightest">
        <p className="text-sm text-brandDarkest text-center max-w-[220px] mb-2">
          <TaggedText message="Need more time to test and maximize your experience? [Book a call|book_sales_call] and get an extended trial!" />
        </p>
        <Button
          text="Book a call"
          theme="highlighted"
          size="MD"
          href="/book-sales-call"
          shouldPassBackgroundLocation
          onClick={ onClickCallback }
          focusable={ false }
        />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center ml-6 my-4 p-4 space-y-4 rounded-md bg-brandPrimary">
        <ScrapingIllustration className="flex-shrink-0 w-24"/>
        <Button
          text="Upgrade account"
          className="text-brandPrimary"
          href="/billing"
          onClick={ onClickCallback }
          theme="inverse_highlighted"
          size="MD"
        />
      </div>
    );
  }
};

function StatusBadge({ xOffset, yOffset, className }: { xOffset: string, yOffset: string, className?: string }) {
  const [ status, setStatus ] = useState<Status>();
  const fetchStatus = useCallback(async (controller: AbortController) => {
    const status = await scraperApi.status.issues({ signal: controller.signal });
    setStatus(status);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchStatus(controller);

    return () => controller.abort();
  }, [ fetchStatus ]);

  return (
    <Transition
      show={ ((status?.numActiveIssues || 0) > 0) }
      enter="transition-all ease-in-out duration-100"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transition-all ease-in-out duration-100"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-50"
    >
      <div className={ cx(
        "flex items-center justify-center w-4 h-4 text-xs text-white bg-brandPrimary-500 rounded-full absolute",
        xOffset,
        yOffset,
        className
      ) }>
        { status?.numActiveIssues }
      </div>
    </Transition>
  );
}

interface Status {
  mostRecentIssue: any;
  numActiveIssues: number;
  numRecentlyResolvedIssues: number;
}

interface ILogoAndIconsProps {
  mobile?: boolean;
  toggleMobileMenu?: () => void;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  showCollapseIcon?: boolean;
}

function LogoAndIcons({ mobile, toggleMobileMenu, collapsed, toggleCollapsed, showCollapseIcon = true }: ILogoAndIconsProps) {
  const Layout = ({ children }: { children: React.ReactNode }) => (
    <div className="flex items-center justify-between">
      { children }
    </div>
  );

  const IconsBlock = ({ children }: { children: React.ReactNode }) => (
    <div className="flex space-x-2">
      { children }
    </div>
  );

  return (
    <>
      { mobile && (
        <Layout>
          <ScraperAPILogo />
          <IconsBlock>
            <MobileMenuIcon
              className="cursor-pointer text-lightGray hover:text-gray transition-colors"
              onClick={ toggleMobileMenu }
            />
          </IconsBlock>
        </Layout>
      )}

      { !mobile && (
        <>
          { collapsed && (
            <SLogo />
          )}
          { !collapsed && (
            <div className="flex flex-row items-center justify-between space-x-4">
              <ScraperAPI />
              { showCollapseIcon && (
                <RiSideBarLine
                  className="text-lightGray hover:text-gray transition-all opacity-0 group-hover:opacity-100"
                  onClick={ toggleCollapsed }
                />
              ) }
            </div>
          )}
        </>
      )}
    </>
  );
}

function ContactSupportSidebarAction({ collapsed }: { collapsed: boolean }) {
  const supportWidget = useSupportWidget();
  const user = useUser();

  return (
    <SidebarAction
      content="Contact Support"
      Icon={ LifebuoyIcon }
      iconStyle="w-5 h-5"
      collapsed={ collapsed }
      onClick={ () => {
        supportWidget?.showSupportForm(user?.email);
      } }
    />
  );
}

function ContactSalesSidebarAction({ collapsed }: { collapsed: boolean }) {
  const useNewSalesForm = useFeatureSwitch("REACT_APP_NEW_SALES_FORM_USERS");
  const supportWidget = useSupportWidget();
  const user = useUser();

  if (useNewSalesForm) {
    return (
      <SidebarNavLink
        to="/book-sales-call"
        addLocationToState
        content="Contact Sales"
        Icon={ HeadsetIcon }
        iconStyle="w-5 h-5"
        collapsed={ collapsed }
      />
    );
  } else {
    return (
      <SidebarAction
        content="Contact Sales"
        Icon={ HeadsetIcon }
        iconStyle="w-5 h-5"
        collapsed={ collapsed }
        onClick={ () => {
          supportWidget?.showSalesForm(user?.email);
        } }
      />
    );
  }
}

function UserMenuSidebarAction({ collapsed }: { collapsed: boolean }) {
  const user = useUser();

  const [ showUserMenu, setShowUserMenu ] = useState(false);

  return (
    <>
      <SidebarAction
        content={ <div className="pl-2"><UserMenuButtonControlled open={ showUserMenu } /></div> }
        icon={ <UserBadge initial={ getUserInitials(user) } /> }
        collapsed={ collapsed }
        onClick={ () => setShowUserMenu(x => !x) }
      />

      <div className={ cx( collapsed && "opacity-0 group-hover:opacity-100 *:z-40 z-40") }>
        <UserMenuControlled showMenu={ showUserMenu } close={ () => setShowUserMenu(false) } widthClassName={ USER_MENU_WIDTH } />
      </div>
    </>
  );
}

const WrapForMobile = ({ mobile, show, mobileMenuToggleCallback, children }: { mobile: boolean, show: boolean, mobileMenuToggleCallback: () => void, children: React.ReactNode }) => {
  const refContent = useRef<any | null>(null);
  const refHeader = useRef<any | null>(null);
  useEffect(() => {
    if (mobile && show) {
      const handleClick = (e: MouseEvent) => {
        const inContent = refContent.current && refContent.current.contains(e.target as Node);
        const inHeader = refHeader.current && refHeader.current.contains(e.target as Node);
        if (!inHeader && !inContent) {
          mobileMenuToggleCallback();
        }
      };
      // Don't use 'click' here. inHeader will be always false. The ref won't contain the target.
      document.addEventListener('mousedown', handleClick);
      return () => document.removeEventListener('mousedown', handleClick);
    }
  }, [ show, mobile, mobileMenuToggleCallback ]);

  if (mobile) {
    return (
      <>
        <div ref={refHeader} className="select-none border-b border-borderColor px-6 pt-3 pb-4 border-l-[3px] border-l-transparent">
          <LogoAndIcons mobile toggleMobileMenu={mobileMenuToggleCallback}/>
        </div>

        <div ref={refContent}>
          <Transition
            show={ show }
            enter="transition-transform ease-in-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute top-0 left-0 h-full overflow-y-auto z-10">
              { children }
            </div>
          </Transition>
        </div>
      </>
    );
  } else {
    return <>{ children }</>;
  }
};

export default function Sidebar() {
  const [ collapsed, setCollapsed ] = useLocalStorage("saSidebarCollapsed", false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [ showMobileMenu, setShowMobileMenu ] = useState(false);

  const reallyCollapsed = useMemo(() => collapsed && !isMobile, [ collapsed, isMobile ]);

  return (
    <WrapForMobile mobile={ isMobile } show={showMobileMenu} mobileMenuToggleCallback={() => setShowMobileMenu(x => !x)}>
      <div className={ cx("relative h-full group *:h-full *:bg-white text-gray", reallyCollapsed && COLLAPSED_WIDTH) }>
        <div className={
          cx(
            "border-r border-borderColor flex flex-col justify-between overflow-y-auto",
            PANEL_WIDTH,
            reallyCollapsed && `absolute z-20 ${COLLAPSED_PANEL_TRANSLATE} group-hover:translate-x-0 transition-transform duration-500 ease-in-out`
          ) }
        >

          <div>
            <SidebarItem
              disabled
              Icon={ SLogo }
              content={ <div className="z-10"><LogoAndIcons showCollapseIcon={ !isMobile } toggleCollapsed={() => setCollapsed(c => !c)} /></div> }
              collapsed={ reallyCollapsed }
            />

            <div className="h-8" />

            <SidebarNavLink
              to="/"
              content="Dashboard"
              Icon={ DashboardIcon }
              iconStyle="w-5 h-5"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              featureSwitch="REACT_APP_API_PLAYGROUND_USERS"
              to="/apiplayground"
              content="API playground"
              Icon={ ApiPlaygroundIcon }
              iconStyle="w-5 h-5"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/projects"
              content="DataPipeline"
              Icon={ DataPipelineIcon }
              iconStyle="w-5 h-5"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/billing"
              content="Billing"
              Icon={ BillingIcon }
              iconStyle="w-5 h-5"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />
          </div>

          <div className="relative">
            <SidebarItem
              disabled
              collapsed={ reallyCollapsed }
              content={ (
                <div className={ cx(reallyCollapsed && "transition-opacity opacity-0 group-hover:opacity-100") }>
                  <UpgradeButton />
                </div>
              ) }
            />

            <SidebarExtLink
              href="https://status.scraperapi.com"
              content={ <div className="flex flex-row gap-x-3 relative">
                <div className="font-droid pl-4">Status Page</div>
                { !reallyCollapsed && <StatusBadge xOffset="left-[108px]" yOffset="top-[-10px]" /> }
              </div> }
              icon={ <>
                <PulseIcon className="relative w-5 h-5" />
                { reallyCollapsed && <StatusBadge xOffset="right-[-14px]" yOffset="top-0" className="group-hover:translate-x-[110px] transition-transform ease-in-out duration-500" /> }
                </> }
              collapsed={ reallyCollapsed }
            />

            <SidebarExtLink
              href="https://www.scraperapi.com/documentation/"
              content="Documentation"
              Icon={ DocumentIcon }
              iconStyle="w-5 h-5"
              collapsed={ reallyCollapsed }
            />

            <SidebarExtLink
              href="https://www.scraperapi.com/affiliates/"
              content="Join our referral program"
              Icon={ RiCoinsLine }
              iconStyle="w-5 h-5"
              collapsed={ reallyCollapsed }
            />

            <ContactSupportSidebarAction collapsed={ reallyCollapsed } />

            <ContactSalesSidebarAction collapsed={ reallyCollapsed } />

            <SidebarItem
              content={ <div className="flex flex-row items-center">
                <div className="border-t border-borderColor w-full"/>
              </div> }
              contentPadding=""
              disabled
            />

            <UserMenuSidebarAction collapsed={ reallyCollapsed } />
          </div>
        </div>
      </div>
    </WrapForMobile>
  );
}
