import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as StartNowIcon } from "assets/icons/onboarding-start-now-icon.svg";
import { ReactComponent as TryApiIcon } from "assets/images/onboarding-startnow-api.svg";
import { ReactComponent as TryDataPipelineIcon } from "assets/images/onboarding-startnow-datapipeline.svg";
import { ReactComponent as BrowseDashboardIcon } from "assets/images/onboarding-startnow-dashboard.svg";

import { cx } from "utils";
import { trackOnboardingEvent } from "utils/Tracking";


function StartNowButton(
  {
    text,
    explanation,
    Icon,
    href,
    trackingEvent
  }: {
    text: string;
    explanation?: string;
    Icon: React.ComponentType<any>;
    href: string;
    trackingEvent?: string;
  }
) {

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex flex-col border border-borderColor hover:border-brandPrimary px-2.5 py-3 gap-y-2.5 cursor-pointer"
      onMouseEnter={ () => setIsHovered(true) }
      onMouseLeave={ () => setIsHovered(false) }
      onClick={ () => {
        trackOnboardingEvent(trackingEvent);
        navigate(href);
      } }
    >
      { Icon && <Icon/> }
      <div className="gap-y-6 flex flex-col">
        <div className="flex flex-col">
          <div className="text-lg text-brandDarkest">{ text }</div>
          <div className={ cx("text-sm text-gray", explanation ? "" : "invisible") }>{ explanation || "&npsp;" }</div>
        </div>
        <StartNowIcon className={ cx("self-end", isHovered ? "mr-0 text-brandPrimary" : "mr-2 text-lightGray") }/>
      </div>
    </div>
  );
}


export default function StartNowButtons() {
  return (
    <>
      <div className="text-2xl font-medium text-brandDarkest pt-10">
        Start now
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <StartNowButton
          text="Try the API"
          explanation="Code playground"
          Icon={ TryApiIcon }
          href="/apiplayground"
          trackingEvent="onboarding_api_playground_clicked"
        />
        <StartNowButton
          text="Try DataPipeline"
          explanation="No-code scraping"
          Icon={ TryDataPipelineIcon }
          href="/projects"
          trackingEvent="onboarding_datapipeline_clicked"
        />
        <StartNowButton
          text="Browse dashboard"
          Icon={ BrowseDashboardIcon }
          href="/"
          trackingEvent="onboarding_dashboard_clicked"
        />
      </div>
    </>
  );
};
