import { useEffect } from "react";
import { Outlet, useLocation, useRevalidator } from "react-router-dom";

import BorderedPage from "components/BorderedPage";
import OnboardingCard from "components/OnboardingCard";
import Panel from "components/Panel";
import RequestsCard from "components/RequestsCard";
import SuccessRateHistoryGraph from "components/SuccessRateHistoryGraph";
import UsageChart from "components/UsageChart";

import { useSupportWidget } from "hooks/useSupportWidget";

import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";


function AutoOpenSupportWidget({ user }: { user: { email: string } | null}) {
  const location = useLocation();
  const supportWidget = useSupportWidget();
  const { openSupportWidget, openSalesWidget } = location.state ? location.state as { openSupportWidget: boolean, openSalesWidget: boolean } : { openSupportWidget: false, openSalesWidget: false };

  useEffect(() => {
    if (openSupportWidget) {
      supportWidget.showSupportForm(user?.email);
    }
    if (openSalesWidget) {
      supportWidget.showSalesForm(user?.email);
    }
  }, [ openSupportWidget, openSalesWidget, supportWidget, user?.email ]);

  return <></>;
}


export default function DashboardHome() {
  const user = useUser();
  const { subscription, appStorage } = useUserProvider();
  const revalidator = useRevalidator();

  return (
    <>
    <BorderedPage title="Home">
      <AutoOpenSupportWidget user={ user } />
      <div className="flex flex-col gap-8 pb-8">
        {!appStorage?.hideOnboarding && (
          <OnboardingCard
            onHide={() => {
              localStorage.setItem("hideOnboarding", "true");
              revalidator.revalidate(); // TODO <-- this is way too much here. should simply update the onboarding state instead of reloading everything
            }}
            apiKey={user?.apiKey ?? ""}
          />
        )}

        <div className="flex flex-col items-stretch lg:flex-row gap-x-8 gap-y-8">
          <div className="w-full lg:w-9/12">
            <UsageChart
              creditsResetOn={
                subscription?.next_renewal_at || subscription?.current_term_end
                  ? new Date((subscription.next_renewal_at || subscription.current_term_end) * 1000)
                  : undefined
              }
              usedCredits={user?.requestCount ?? 0}
              totalCredits={user?.apiCallLimit ?? 0}
              usedConcurrentRequests={user?.concurrentRequests ?? 0}
              totalConcurrentRequests={user?.concurrentRequestLimit ?? 0}
            />
          </div>
          <div className="w-full lg:w-3/12 min-w-[220px]">
            <RequestsCard
              success={user ? user?.requestCount : 0}
              failure={user ? user.failedRequests : 0}
            />
          </div>
        </div>
        <SuccessRateHistoryGraph />
        <Panel
          ctaHref="https://scraperapi.hund.io/"
          ctaText="Status page"
          message="If you are experiencing problems scraping sites, please take a look at our status page before reporting an issue."
        />
        <Panel
          ctaHref="/billing"
          ctaText="Billing Page"
          message="If you are running out of credits you can renew your subscription on the billing page"
        />
      </div>
    </BorderedPage>
      <Outlet /> {/* to support modals */}
    </>
  );
}
