import type { TypeSelectedCallback } from "./typeSelectedCallback";
import { projectIcon } from "components/hosted-scraping/ProjectIcon";
import { projectTypeToTitleAndDescription, ProjectVariant } from "sdecontent";

export const AsyncUrlBar = ({variant, callback}:{variant: ProjectVariant, callback: TypeSelectedCallback}) => {
  const {title, description, icon} = projectTypeToTitleAndDescription(variant, 'async_urls');
  const Icon = projectIcon(icon);
  return (
    <div 
      className="w-full border border-slate-200 p-2 hover:bg-slate-50 cursor-pointer flex flex-row items-center mt-4 mb-4"
      onClick={() => callback('async_urls')}
    > 
      <div className="w-10 h-10 p-2 border border-slate-200 flex items-center justify-center mr-2">
        <Icon/>
      </div>
      <div className="flex flex-col">
        <div className="text-base text-black font-semibold">
          {title}
        </div>
        <div className="text-sm text-gray">
          {description}
        </div>
      </div>
    </div>
  );

};