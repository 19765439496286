import { ProjectConfigWithStats } from "providers/HostedScrapingProvider";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { formatDate } from "./ProjectFormatDate";
import { ProjectStatus } from "./ProjectStatus";
import { projectIcon } from "components/hosted-scraping/ProjectIcon";
import { NavLink } from "react-router-dom";

import { ReactComponent as AddNewProjectIcon } from "assets/icons/project-add-new.svg";
import { iconTypeHS, projectTypeHS } from "sdecontent";

type IProjectCardProps = {
  project: ProjectConfigWithStats,
}

const navigateToProject = (navigate: NavigateFunction, projectId: number) => () => {
  navigate(`/projects/${encodeURIComponent(projectId)}`);
}

export const NewProjectCard = () => {
  return (
  <NavLink to="/projects/new">
    <div className="h-60 w-56 flex flex-col border border-solid p-3 text-white bg-brandPrimary hover:bg-[#1A20BC] items-center justify-center cursor-pointer"
        onClick={() => {}}>
      <div className="mb-6"><AddNewProjectIcon width="24px" height="24px" /></div>
      <div className="text-xl">New project</div>
    </div>
  </NavLink>
  )
}

const ProjectCard = ({project}: IProjectCardProps) => {
  const navigate = useNavigate();
  const Icon = projectIcon(iconTypeHS(project.config.type));
  return (
    <div className="h-60 w-56 flex flex-col justify-between border border-solid border-slate-200 p-6 bg-white hover:bg-slate-50 cursor-pointer"
        onClick={navigateToProject(navigate, project.id)}>
      <div><ProjectStatus className="text-sm text-slate-500" project={project}/></div>

      <div>
        <div className="mt-4 mb-4 text-xl font-medium truncate">{project.name}</div>
        <div className="font-normal text-xs text-slate-400">Last run: {formatDate(project.lastRunAt)}</div>
        <div className="font-normal text-xs text-slate-400">Credits spent: {project.credits}</div>
      </div>

      <div className="flex flex-row text-sm text-slate-500">
        <div className="order border-slate-200 flex items-center justify-center mr-2">
          <Icon className="w-3.5 h-3.5"/>
        </div>
        <div>{projectTypeHS(project.config?.type)}</div>
      </div>
    </div>)
}

type IProjectGridViewProps = {
  projects: ProjectConfigWithStats[],
}

export default function ProjectGridView({ projects }: IProjectGridViewProps) {
  return (
    <div className="flex flex-row flex-wrap gap-6">
      <NewProjectCard/>
      {projects?.map((proj) => (<ProjectCard project={proj} key={proj.id}/>))}
    </div>
  );
};
