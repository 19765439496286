import scraperApi, { ApiError } from "api";

import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import AuthForm from "components/AuthForm";
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Login() {
  const navigate = useNavigate();

  return (
    <AuthPage
      title="Sign in"
      heading="Log in to your account"
    >
      <AuthForm
        googleButtonText="Sign in with Google"
        googleCallback={async (token: string) => {
          try {
            return await scraperApi.auth.google.signin(token);
          } catch (error) {
            const apiErrorCode = error instanceof ApiError ? error.error_code : (axios.isAxiosError(error) ? (error.response as AxiosResponse<{ error_code: string }>)?.data?.error_code : undefined);
            if (apiErrorCode === "user_not_found") {
              return navigate("/offer-social-signup", {
                state: {
                  idToken: token,
                  socialEmail: (error instanceof ApiError) && error.details.email,
                  name: (error instanceof ApiError) && error.details.givenName
                }
              });
            } else {
              throw error;
            }
          }
        }}
        gitHubButtonText="Sign in with GitHub"
        gitHubRedirectUri={`${process.env.REACT_APP_API_URL}/oauth/github/sign-in`}
        emailButtonText="Log in with email"
        emailCallback={scraperApi.auth.login}
        showReCaptcha
        defaultErrorMessage={(authWith?: string) => { return `There was an error signing in${authWith && (" with " + authWith)}. Please try again later.`}}
      />

      <AuthPageLinks>
        <AuthPageLink text="Don't have an account?" link={{ to: "/signup", text: "Sign up here" }} />
        <AuthPageLink text="Forgot your password?" link={{ to: "/forgot-password", text: "Reset password" }} />
      </AuthPageLinks>

    </AuthPage>
  );
}
