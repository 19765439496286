import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BackArrowIcon } from "assets/icons/onboarding-back-arrow.svg";

import scraperApi from "api";
import { cx } from "utils";
import { trackOnboardingEvent } from "utils/Tracking";

import ButtonBar from "./ButtonBar";
import StepIndicator from "./StepIndicator";

import { pageData } from "./Pages";
import StartNowButtons from "./StartNowButtons";
import useOnboardingStep from "./useOnboardingStep";
import StartNowLinks from "./StartNowLinks";
import QuestionsPage from "./QuestionsPage";

import FormWithValidation from "components/FormWithValidation";
import {
  getUserAnswersFromLocalStorage,
  updateUserAnswers,
  UserAnswerType
} from "components/FormWithValidation/localStorage";


const ONBOARDING_ANSWERS_LOCAL_STORAGE_KEY = "sa_onboarding_answers";

export default function OnboardingPage() {
  const navigate = useNavigate();

  const { step } = useOnboardingStep();

  const isFirstStep = step === 2;
  const isLastStep = step === pageData.length;

  const currentPage = useMemo(() => pageData[step - 1], [ step ]);
  const [ userAnswers, setUserAnswers ] = useState<UserAnswerType>(getUserAnswersFromLocalStorage(ONBOARDING_ANSWERS_LOCAL_STORAGE_KEY));

  const [ isMandatoryAnswerMissing, setIsMandatoryAnswerMissing ] = useState(true);


  const onFormChangeCallback = useCallback((event: React.ChangeEvent<HTMLFormElement>) => {
    setUserAnswers(getUserAnswersFromLocalStorage(ONBOARDING_ANSWERS_LOCAL_STORAGE_KEY));
  }, []);

  const onFormSubmitCallback = useCallback(async (event: any) => {
    event.preventDefault();

    const formJson: { [ index: string ]: string[] } = {};
    updateUserAnswers(formJson, event.target);
    await scraperApi.onboarding.saveAnswers(formJson);

    navigate(`/welcome/${ step + 1 }`);
  }, [ navigate, step ]);


  return (
    <div className="flex flex-col items-start self-stretch gap-6">
      <div className={ cx(isFirstStep && "invisible", "cursor-pointer") }>
        <BackArrowIcon
          onClick={ () => {
            trackOnboardingEvent("onboarding_back_clicked_on_page_" + step);
            navigate(`/welcome/${ step - 1 }`)
          } }/>
      </div>
      <StepIndicator step={ step.toString(10) || "" } totalSteps={ pageData.length }/>

      <FormWithValidation
        onSubmit={ onFormSubmitCallback }
        onChange={ onFormChangeCallback }
        validationCallback={ (valid) => setIsMandatoryAnswerMissing(!valid) }
        localStorageKey={ ONBOARDING_ANSWERS_LOCAL_STORAGE_KEY }
        className="w-full"
      >
        <QuestionsPage
          page={ currentPage }
          userAnswers={ userAnswers }
        />

        { !isLastStep && (
          <ButtonBar
            nextEnabled={ !isMandatoryAnswerMissing }
            skipTrackingEvent={ "onboarding_skip_clicked_on_page_" + step }
          />
        ) }
      </FormWithValidation>
      { isLastStep && (
        <>
          <StartNowButtons/>
          <StartNowLinks/>
        </>
      ) }
    </div>
  );
};
