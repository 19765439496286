import { useCallback, useEffect } from "react";

import { cx } from "utils";

import ServiceScriptProvider from "providers/ServicesProvider/ServiceScriptProvider";


interface IHubSpotWindow {
  hbspt?: {
    forms?: {
      create?: (options: {
        region: string;
        portalId: string;
        formId: string;
      }) => void;
    };
  };
}

export default function BookSalesCallModalContent() {

  const PLACEHOLDER_DIV_CLASS = "hsform-book-sales-call-container";
  const RENDER_SCRIPT_ID = "hsform-render-scriptlet";

  const insertHSFormRenderScript = useCallback(() => {
    if (!document.getElementById(RENDER_SCRIPT_ID) && (window as IHubSpotWindow).hbspt) {
      const renderScript = document.createElement("script");
      renderScript.id = RENDER_SCRIPT_ID;
      renderScript.type = "text/javascript";
      renderScript.innerText = `
        hbspt.forms.create({
          region: "na1",
          portalId: "6840215",
          formId: "b79d73cb-dbd2-4a4e-8f1d-570698d23064"
        });
      `;

      document.getElementsByClassName(PLACEHOLDER_DIV_CLASS)[0]?.appendChild(renderScript);
    }
  }, []);

  const removeHSFormRenderScript = useCallback(() => {
    document.getElementById(RENDER_SCRIPT_ID)?.remove();
    // also clean up the form itself
    document.getElementsByClassName(PLACEHOLDER_DIV_CLASS)[0]?.childNodes.forEach(child => child.remove());
  }, []);

  const hubSpotScript = {
    name: "HubSpot Form Embed Script",
    domId: "hsforms-embed-script",
    src: "//js.hsforms.net/forms/embed/v2.js",
    onLoadCallback: insertHSFormRenderScript,
    onUnloadCallback: removeHSFormRenderScript
  };

  useEffect(() => {
    insertHSFormRenderScript();

    return () => {
      removeHSFormRenderScript();
    };
  }, [ insertHSFormRenderScript, removeHSFormRenderScript ]);


  return (
    <>
      <ServiceScriptProvider { ...hubSpotScript } />
      <div className="flex flex-col p-5 gap-y-12">
        <div className="text-gray">
          Need more time to explore all our features? Book a sales call with us! Fill out this short form, and we’ll add
          30 more days to your trial period as you explore further.
        </div>
        <div className={ cx(PLACEHOLDER_DIV_CLASS, "min-h-[650px]") } />
      </div>
    </>
  );
};
