interface IStepIndicatorProps {
  step: string;
  totalSteps: number;
}


export default function StepIndicator({ step, totalSteps }: IStepIndicatorProps) {
  return <div className="text-lightGray font-normal">
    Step { step } / { totalSteps }
  </div>;
};
