import React, { ButtonHTMLAttributes, forwardRef, MouseEventHandler, ReactElement } from "react";
import ReactDOMServer from "react-dom/server";
import { useLocation } from "react-router-dom";

import { cx } from "utils";

import ExtLink from "components/ExtLink";
import StatefulLink from "components/StatefulLink";
import { globalTooltipClass } from "components/Tooltip";

import { Themes } from "styles/themes";


type btnSizes = keyof typeof sizes;
const sizes = {
  XS: "px-2.5 py-1.5 text-xs",
  SM: "px-3 py-2 text-sm",
  MD: "px-4 py-2 text-sm",
  MD_NO_PADDING: "text-sm",
  LG: "px-4 py-2 text-base", // TODO: min-width?
  XL: "px-6 py-3 text-base min-w-[8rem]",
  XL2: "px-6 py-3 text-lg"
};

interface ButtonTheme {
  style: string;
  hoverStyle?: string;
  uppercase: boolean;
  iconStyle?: string;
  iconSize?: string;
}

const buttonThemes: Themes<ButtonTheme> & {
  custom_signin: ButtonTheme,
  borderless_secondary: ButtonTheme,
  onboarding_option: ButtonTheme,
  onboarding_option_selected: ButtonTheme,
  primary_disabled: ButtonTheme
  code_editor: ButtonTheme,
  code_editor_play_button: ButtonTheme,
  cancel_button: ButtonTheme
} = {
  error: {
    style: "bg-red-600 text-white border border-red-700 shadow-sm",
    hoverStyle: "hover:bg-red-700 hover:border-red-800",
    uppercase: true
  },
  warning: {
    style: "bg-orange-600 text-white border border-orange-700 shadow-sm",
    hoverStyle: "hover:bg-orange-700 hover:border-orange-800",
    uppercase: true
  },
  highlighted: {
    style: "bg-brandPrimary-600 text-white border shadow-sm",
    hoverStyle: "hover:bg-brandPrimary-700",
    uppercase: true
  },
  inverse_highlighted: {
    style: "bg-white text-brandPrimary border shadow-sm",
    hoverStyle: "hover:bg-lightGray",
    uppercase: true
  },
  default: {
    style: "bg-white text-black border border-gray-600 shadow-sm",
    hoverStyle: "hover:bg-slate-100 hover:border-gray-700",
    uppercase: true
  },
  disabled: {
    style: "bg-white text-gray border border-lightGray shadow-sm cursor-default",
    uppercase: true
  },
  primary_disabled: {
    style: "bg-[#BABDF7] text-white shadow-sm cursor-default",
    uppercase: true
  },

  custom_signin: {
    style: "rounded bg-signinGray w-[224px] text-[#e8eaed] !text-[14px] !font-google font-medium p-[2px]",
    hoverStyle: "hover:bg-signinGray-50",
    uppercase: false,
    iconStyle: "bg-white p-[9px] rounded-l-[3px] border-[#e8eaed]",
    iconSize: "w-[18px] h-[18px]"
  },

  // This is the same as default without the borders
  cancel_button: {
    style: "bg-white text-black border border-white",
    hoverStyle: "hover:bg-slate-100 hover:border hover:border-gray-700",
    uppercase: true
  },
  borderless_secondary: {
    style: "text-lightGray",
    hoverStyle: "hover:text-gray",
    uppercase: true
  },
  code_editor: {
    style: "bg-codeViewPurple text-white border border-gray shadow-sm cursor-default",
    uppercase: true
  },
  code_editor_play_button: {
    style: "bg-green text-white border border-gray shadow-sm cursor-default",
    uppercase: true
  },

  onboarding_option: {
    style: "text-gray border border-gray !font-sans",
    hoverStyle: "hover:border-brandPrimary hover:outline hover:outline-1 hover:outline-brandPrimary",
    iconSize: "w-[28px] h-[28px]",
    uppercase: false
  },
  onboarding_option_selected: {
    style: "text-brandPrimary border border-brandPrimary !font-sans outline outline-1 outline-brandPrimary",
    iconSize: "w-[28px] h-[28px]",
    uppercase: false
  }
};

type buttonTheme = keyof typeof buttonThemes;

const Content = ({
  Icon,
  text,
  uppercase,
  className,
  iconStyle,
  iconSize,
  focusable = true,
  htmlTooltip
}: {
  Icon?: React.ComponentType<any>;
  text: string;
  uppercase: boolean;
  className?: string;
  iconStyle?: string;
  iconSize?: string;
  focusable?: boolean;
  htmlTooltip?: string;
}) => {
  return (
    <div
      className={ cx(
        className,
        uppercase && "uppercase",
        focusable && "group-focus:outline-none group-focus:ring-2 group-focus:ring-offset-2",
        htmlTooltip && globalTooltipClass,
        "font-droid text-center justify-center items-center transition-colors flex-shrink-0 duration-150"
      ) }
      data-tooltip-html={ htmlTooltip }
    >
      <div className="flex items-center w-full gap-x-2">
        { Icon && (
          <div className={ iconStyle }>
            <Icon className={ cx(iconSize ?? "w-5 h-5", "justify-start flex-shrink-0") }/>
          </div>
        ) }
        <span className="self-center mx-auto">{ text }</span>
      </div>
    </div>
  )
};

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "size" | "onClick"> {
  text: string;
  onClick?: MouseEventHandler<HTMLElement>;
  href?: string;
  Icon?: React.ComponentType<any>;
  iconStyle?: string;
  iconAbsolute?: boolean;
  fullWidth?: boolean;
  centerAlign?: boolean;
  align?: "top" | "center" | "bottom";
  size?: btnSizes;
  shouldPassBackgroundLocation?: boolean;
  theme?: buttonTheme;
  focusable?: boolean;
  tooltip?: string | ReactElement;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      onClick,
      href,
      Icon,
      iconStyle,
      iconAbsolute = false,
      fullWidth,
      centerAlign,
      align,
      className,
      shouldPassBackgroundLocation,
      size = "MD",
      theme = "highlighted",
      type = "button",
      id,
      focusable = true,
      tooltip,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const location = useLocation();
    const disabled = (theme === "disabled") || (theme === "primary_disabled");
    const btnType = buttonThemes[theme];
    const btnSize = sizes[size];
    const htmlTooltip = typeof tooltip === "string" ? tooltip : (tooltip ? ReactDOMServer.renderToStaticMarkup(tooltip as ReactElement) : undefined);

    if (href) {
      if (href.startsWith("http")) {
        return (
          <ExtLink
            href={href}
            className={cx(fullWidth && "w-full", centerAlign && "self-center", "group")}
            id={id}
            onClick={onClick}
          >
            <Content
              text={text}
              Icon={Icon}
              className={cx(
                className,
                fullWidth ? "flex" : "inline-flex",
                btnType?.style,
                !disabled && btnType?.hoverStyle,
                btnSize
              )}
              uppercase={Boolean(btnType?.uppercase)}
              iconStyle={cx(btnType?.iconStyle, iconStyle, iconAbsolute && "absolute")}
              iconSize={btnType?.iconSize}
              focusable={focusable}
              htmlTooltip={htmlTooltip}
            />
          </ExtLink>
        )
      } else {
        return (
          <StatefulLink
            to={href}
            onClick={onClick}
            className={cx(fullWidth && "w-full", centerAlign && "self-center", "group")}
            id={id}
            state={{
              ...(shouldPassBackgroundLocation && {
                backgroundLocation: location
              })
            }}
            disabled={disabled}
          >
            <Content
              text={text}
              Icon={Icon}
              className={cx(
                className,
                fullWidth ? "flex" : "inline-flex",
                btnType?.style,
                !disabled && btnType?.hoverStyle,
                btnSize
              )}
              uppercase={Boolean(btnType?.uppercase)}
              iconStyle={cx(btnType?.iconStyle, iconStyle, iconAbsolute && "absolute")}
              iconSize={btnType?.iconSize}
              focusable={focusable}
              htmlTooltip={htmlTooltip}
            />
          </StatefulLink>
        )
      }
    }

    if (onClick || (type === "submit")) {
      return (
        <button
          ref={ref}
          type={(type === "submit" && disabled) ? "button" : type}
          id={id}
          onClick={disabled ? () => {} : onClick}
          className={cx(
            fullWidth && "w-full",
            centerAlign && "self-center",
            align === "top" && "self-start",
            align === "center" && "self-center",
            align === "bottom" && "self-end",
            "group"
          )}
          { ...props }
        >
          <Content
            text={text}
            className={cx(
              className,
              fullWidth ? "flex" : "inline-flex",
              btnType?.style,
              !disabled && btnType?.hoverStyle,
              btnSize
            )}
            uppercase={Boolean(btnType?.uppercase)}
            Icon={Icon}
            iconStyle={cx(btnType?.iconStyle, iconStyle, iconAbsolute && "absolute")}
            iconSize={btnType?.iconSize}
            focusable={focusable}
            htmlTooltip={htmlTooltip}
          />
        </button>
      );
    }

    return <Content
      text={text}
      className={cx(
        className,
        fullWidth ? "flex w-full" : "inline-flex",
        btnType?.style,
        btnSize
      )}
      uppercase={Boolean(btnType?.uppercase)}
      iconStyle={btnType?.iconStyle}
      iconSize={btnType?.iconSize}
      focusable={focusable}
      htmlTooltip={htmlTooltip}
    />;
  }
);

export default Button;
