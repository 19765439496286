import { IssueSeverity } from "providers/IssuesProvider";
import TaggedText from "components/TaggedText";
import { cx } from "utils";

const severityToBackgroundColor = (severity: IssueSeverity): string => {
  switch (severity) {
    case 'blocking':
    case 'failure':
    case 'warning':
      return 'bg-orange-500';
    case 'info':
      return 'bg-brandLightest-75';
  }
}

interface IBannerProps {
  title: string;
  dismissible: boolean;
  severity: IssueSeverity;
}

export function Banner({title, severity = 'info'}: IBannerProps) {
  const bgColor = severityToBackgroundColor(severity);
  return (<div className={cx("flex bg-brandLightest-75 text-sm text-center p-2.5", bgColor)}>
    <span className="w-full">
      <TaggedText
        message={title}
        className="text-sm text-brandDarkest-650"
        linkStyle="font-bold hover:text-brandDarkest-800"
      />
    </span>
    {/* 
    
    It's disabled for 2 reasons
    1. The XIcon is misaligned
    2. It's not working. It has no mechanism to know which issue was dismissed
       We could store the id and the happened_at if we wanted to
       + we still couldn't dismiss the out of credit issues
    
    { dismissible && (
      <span><XIcon className="text-gray"/></span>
    )} */}
  </div>);
}