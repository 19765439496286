import { cx, fmtNumber } from "utils";
import { ReactNode } from 'react';


type size = keyof typeof barSizes;
const barSizes = {
  MD: "h-[10px] w-full",
  SM: "h-[8px] w-3/4"
};

const actualLabelSizes = {
  MD: "text-2xl font-medium text-brandDarkest",
  SM: "text-sm text-gray"
};

const totalLabelSizes = {
  MD: "text-gray",
  SM: "text-sm text-gray"
}

interface IUsageBarProps {
  total: number;
  actual: number;
  label?: string;
  size?: size;
  bgColor?: string;
  children?: ReactNode
}


export default function UsageBar({
  actual = 0,
  total = 0,
  label = "",
  size = "MD",
  bgColor = "bg-brandPrimary",
  children
}: IUsageBarProps) {

  return (
    <div className="flex flex-col space-y-2">
      { label && (<div>
          <span className={actualLabelSizes[size]}>{fmtNumber(actual)}</span>
          <span className={totalLabelSizes[size]}>/ {fmtNumber(total)} {label}</span>
        </div>
      )}
      <div className={cx(barSizes[size], "bg-borderColor", "relative")}>
        <div className={cx("absolute inset-0 w-0 h-full", bgColor)}
             style={{ width: `${Math.min(actual / total * 100, 100)}%` }}
        />
      </div>
      {children}
    </div>
  );

};
