import TaggedText from "components/TaggedText";


export function ErrorMessage(
  {
    errorMessage,
    closeModalCallback = () => {}
  }: { errorMessage: string | undefined, closeModalCallback?: () => void }
) {
  if (errorMessage) {
    return (
      <div className="text-red-500">
        <span className="font-bold">Error:</span>{ " " }<TaggedText message={ errorMessage }
                                                                    tagCallbacks={ { "contact_support": closeModalCallback } }
                                                                    linkStyle="transition-colors text-red-500 hover:text-red-800"
      />
      </div>
    );
  } else {
    return <></>;
  }
}
