import { useCallback, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { NavigateFunction, useNavigate, useRevalidator } from "react-router-dom";

import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";
import { ReactComponent as GitHubIcon } from "assets/icons/github-icon.svg";

import Button from "components/Button";
import Form from "components/Form";
import { useGoogleOAuth } from "hooks/useGoogleOAuth";
import scraperApi from "api";
import SeparatorText from "components/SeparatorText";
import { EmailField, ErrorMsg, PasswordField } from "components/InputField";
import ReCaptchaField from "components/ReCaptchaField";
import Checkbox from "components/Checkbox";
import ExtLink from "components/ExtLink";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";


const handleAuthResponse = async (response: any, navigate: NavigateFunction, successCallback: () => Promise<void>, gaEvent?: string) => {
  if (response.includes("A sign in link has been sent to ")) {
    return navigate("/magic-link", { replace: true });
  }

  await successCallback?.();

  if (gaEvent) {
    window?.gtag("event", gaEvent);
  }
};


interface OAuthButtonsProps {
  googleButtonMode?: "signup" | "signin";
  googleButtonText?: string;
  googleCallback?: (token: string) => Promise<any>;
  googleRedirect?: string;
  gitHubButtonText?: string;
  gitHubRedirectUri?: string;
  defaultErrorMessage: ((authWith?: string) => string) | string;
}

function OAuthButtons(
  {
    googleButtonMode,
    googleButtonText,
    googleCallback,
    googleRedirect,
    gitHubButtonText,
    gitHubRedirectUri,
    defaultErrorMessage
  }: OAuthButtonsProps
) {

  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const googleBtnElm = useRef(null);
  const [ oAuthError, setOAuthError ] = useState<string | null>(null);

  const { hideGoogleOAuth } = useGoogleOAuth({
    googleButtonMode: googleButtonMode || "signin",
    setOAuthError,
    googleBtnElm,
    credentialsCallback: async (googleCredentials: any) => {
      try {
        const response = await googleCallback?.call(undefined, googleCredentials.credential);

        return handleAuthResponse(
          response,
          navigate,
          async () => {
            if (googleRedirect) {
              revalidator.revalidate();
              return navigate(googleRedirect);
            } else {
              return revalidator.revalidate();
            }
          }
        );
      } catch (err) {
        setOAuthError((err as Error).message || (typeof defaultErrorMessage === "string" ? defaultErrorMessage : defaultErrorMessage("Google")));
      }
    }
  });

  const gitHubConnect = useCallback(async () => {
      try {
        const gitHubState = await scraperApi.auth.github.state();
        window.location.href = `https://github.com/login/oauth/authorize?scope=user:email&client_id=${process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID}&state=${gitHubState}&redirect_uri=${gitHubRedirectUri}`;
      } catch (error) {
        setOAuthError((error as Error).message || (typeof defaultErrorMessage === "string" ? defaultErrorMessage : defaultErrorMessage("GitHub")));
      }
    },
    [
      setOAuthError, gitHubRedirectUri, defaultErrorMessage
    ]
  );


  return (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 lg:space-x-4">
        {!hideGoogleOAuth && googleCallback && googleButtonText && (
          <div ref={googleBtnElm}>
            <Button
              text={googleButtonText}
              theme="custom_signin"
              size="MD_NO_PADDING"
              onClick={() => setOAuthError(null)}
              Icon={GoogleIcon}
            />
          </div>
        )}
        {gitHubRedirectUri && gitHubButtonText && (
          <div>
            <Button
              text={gitHubButtonText}
              theme="custom_signin"
              size="MD_NO_PADDING"
              onClick={gitHubConnect}
              Icon={GitHubIcon}
            />
          </div>
        )}
      </div>
      <p className="mt-2 text-sm text-red-600">{oAuthError}</p>
    </>
  );
}

interface AuthFormProps extends OAuthButtonsProps {
  emailButtonText?: string;
  emailCallback?: (email: string, password: string, reCaptchaToken: string) => Promise<any>;

  readonlyEmail?: string;
  askForPassword?: boolean;
  showTerms?: boolean;
  showReCaptcha?: boolean;
}

export default function AuthForm(
  {
    googleButtonMode,
    googleButtonText,
    googleCallback,
    googleRedirect,
    gitHubButtonText,
    gitHubRedirectUri,
    emailButtonText,
    emailCallback,
    defaultErrorMessage,
    readonlyEmail,
    askForPassword = true,
    showTerms = false,
    showReCaptcha = false
  }: AuthFormProps
) {

  const navigate = useNavigate();
  const methods = useForm();
  const { register, resetField } = methods;
  const captchaElm = useRef<any>(null);
  const revalidator = useRevalidator();
  const [ formError, setFormError ] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      setFormError(null);

      try {
        const response = await emailCallback?.call(
          undefined,
          data.email,
          data.password,
          data.reCaptchaToken
        );

        return handleAuthResponse(
          response,
          navigate,
          async () => { revalidator.revalidate(); }
        );
      } catch (error) {
        setFormError((error as Error).message || (typeof defaultErrorMessage === "string" ? defaultErrorMessage : defaultErrorMessage()));
        captchaElm?.current?.reset();
        resetField("reCaptchaToken");
      }
    },
    [
      setFormError, navigate, revalidator, resetField, emailCallback, defaultErrorMessage
    ]
  );

  return (
    <>
      {(googleCallback || gitHubRedirectUri) && (
        <>
          <OAuthButtons
            googleButtonMode={googleButtonMode}
            googleButtonText={googleButtonText}
            googleCallback={googleCallback}
            googleRedirect={googleRedirect}
            gitHubButtonText={gitHubButtonText}
            gitHubRedirectUri={gitHubRedirectUri}
            defaultErrorMessage={defaultErrorMessage}
          />
          <div className="my-8">
            <SeparatorText label="or"/>
          </div>
        </>
      )}
      <Form onSubmit={onSubmit} methods={methods}>
        <div className="flex flex-col space-y-4">
          <EmailField readonlyEmail={readonlyEmail} />
          { askForPassword &&
            <PasswordField/>
          }
          { showReCaptcha &&
            <>
              <ReCaptchaField
                // We do this to reset the captcha after each submission try
                setElm={(elm: any) => (captchaElm.current = elm.current)}
              />
              <ErrorMsg name="reCaptchaToken" />
            </>
          }

          { showTerms &&
            <Checkbox
              className="!mt-6"
              label={
                <>
                  By signing up you agree to our{" "}
                  <ExtLink
                    className="underline text-brandPrimary underline-offset-2"
                    href="https://www.scraperapi.com/terms/"
                  >
                    Terms & Conditions
                  </ExtLink>
                  {" "}and to scrape in compliance with the Terms & Conditions of the websites you intend to scrape.
                </>
              }
              register={register}
              required
              name="terms"
              id="terms"
            />
          }
        </div>

        <p className="mt-2 text-sm text-red-600">{formError}</p>

        { emailButtonText && emailCallback && (
          <div className="my-8">
            <Button
              text={emailButtonText}
              theme="highlighted"
              size="LG"
              type="submit"
              Icon={ FormSubmittingSpinner }
              onClick={() => {}}
              fullWidth
            />
          </div>
        )}
      </Form>
    </>
  );

};
