import { NavLink } from "react-router-dom";

import { cx } from "utils";


interface IQuickLinkCardProps {
  Icon: React.ComponentType<any>;
  title: string;
  description: string;
  disabled?: boolean;
}

function QuickLinkCardContent(
  {
    Icon,
    title,
    description,
    disabled = false
  }: IQuickLinkCardProps
) {
  const iconStyle = 'w-12 h-12 shrink-0 mr-4 border-lightgray-600 flex items-center justify-center bg-[#1a22e4]/[0.08] text-brandPrimary';

  return (
    <div className={ cx("w-72 h-52 p-5 border", !disabled && "hover:bg-slate-50") }>
      <div className="flex flex-col space-y-2">
        <div className={ iconStyle }>
          <div className="w-7 h-7 flex items-center justify-center"><Icon className="w-5 h-5 text-brandPrimary"/>
          </div>
        </div>
        <span className="font-semibold">{ title }</span>
        <span className="text-sm text-gray">{ description }</span>
      </div>
    </div>
  );
}

interface QuickLinkCardProps extends IQuickLinkCardProps {
  to: string;
}
export const QuickLinkCard = ({Icon, title, description, to, disabled = false}: QuickLinkCardProps) => {
  if (disabled) {
    return (
      // TODO in the figma design system there's a separate disabled style for buttons. we should use that instead of
      //  this 'opacity-30' stuff
      <span className="opacity-30">
        <QuickLinkCardContent
          Icon={ Icon }
          title={ title }
          description={ description }
          disabled
        />
      </span>
    );
  } else {
    return (
      <NavLink to={ to }>
        <QuickLinkCardContent
          Icon={ Icon }
          title={ title }
          description={ description }
        />
      </NavLink>
    )
  }
}
