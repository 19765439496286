import { useEffect } from "react";

import { ServiceInfo, useServices } from "./index";


export type ServiceScript = ServiceInfo & {
  src: string;
  onLoadCallback?: () => void;
  onUnloadCallback?: () => void;
};

export default function ServiceScriptProvider(serviceScript: ServiceScript) {

  const { findService, loadService } = useServices();

  useEffect(() => {
    if (!findService(serviceScript)) {
      // load the script only if it is not loaded already
      if (!document.getElementById(serviceScript.domId)) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = serviceScript.domId;
        script.src = serviceScript.src;
        script.onload = () => {
          if (loadService(serviceScript)) {
            serviceScript.onLoadCallback?.();
          }
        }
        document.body.appendChild(script);
      }
    }
  }, [ serviceScript, findService, loadService ]);

  return <></>;

};
