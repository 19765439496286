import React, { InputHTMLAttributes, ReactNode } from "react";

import InputField from "components/InputField";
import { cx } from "utils";


interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  required: boolean;
  id: string;
  register?: any;
  name: string;
}

export default function Checkbox({
  label,
  required,
  name,
  ...props
}: ICheckboxProps) {
  return (
    <div className={cx("flex items-start gap-x-3", props.className)}>
      <InputField
        id={props.id}
        name={name}
        type="checkbox"
        required={required}
        className="focus:ring-brandPrimary focus:border-brandPrimary block !w-[18px] !h-[18px] text-base border-gray"
      />
      <label htmlFor={props.id} className="-mt-1 text-sm select-none text-gray">
        {label}
      </label>
    </div>
  );
}
