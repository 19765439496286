export const FetcherKeys = {
  BILLING_ADDRESS: "billing-address-fetcher",
  COUPON: "coupon-fetcher",
  PAYMENT_SOURCES: "payment-sources-fetcher",
  UPGRADE_SUBSCRIPTION: "upgrade-subscription-fetcher",
}

export const Fetchers = {
  BILLING_ADDRESS_FETCHER: { key: FetcherKeys.BILLING_ADDRESS },
  COUPON_FETCHER: { key: FetcherKeys.COUPON },
  PAYMENT_SOURCES_FETCHER: { key: FetcherKeys.PAYMENT_SOURCES },
  UPGRADE_SUBSCRIPTION_FETCHER: { key: FetcherKeys.UPGRADE_SUBSCRIPTION },
};
