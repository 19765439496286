import { ReactComponent as OnboardingTrialLock } from "assets/icons/onboarding-trial-lock.svg";
import { ReactComponent as OnboardingTrialEnvelope } from "assets/icons/onboarding-trial-envelope.svg";
import { ReactComponent as OnboardingTrialCalendar } from "assets/icons/onboarding-trial-calendar.svg";
import { ReactComponent as OnboardingTrialSeparator } from "assets/icons/onboarding-trial-separator.svg";


interface ITrialStep {
  icon: JSX.Element;
  headline: string;
  content: string;
}

const trialSteps: ITrialStep[] = [
  {
    icon: <OnboardingTrialLock />,
    headline: "Today: Get instant access",
    content: "We’ll send you an email notification"
  },
  {
    icon: <OnboardingTrialEnvelope />,
    headline: "Day 5: Trial Reminder",
    content: "We’ll send you an email notification"
  },
  {
    icon: <OnboardingTrialCalendar />,
    headline: "Day 7: Trial Ends",
    content: "Trial credits will expire, and you will be able to subscribe to your desired plan."
  }
];


function FreeTrialStep(
  {
    icon,
    headline,
    content,
    appendSeparator
  }: ITrialStep & { appendSeparator: boolean }
) {
  return (
    <>
      <div className="py-2.5">{ icon }</div>
      <div className="flex flex-col items-start justify-self-start">
        <div className="text-brandDarkest font-medium text-base">{ headline }</div>
        <div className="text-gray text-xs text-start">{ content }</div>
      </div>
      { appendSeparator && <Separator /> }
    </>
  );
}

function Separator() {
  return (
    <>
      <OnboardingTrialSeparator />
      <div/> {/* empty div to fill up the next grid in the row */}
    </>
  )
}

export default function FreeTrialSteps() {
  return (
    <div className="grid grid-cols-[24px_1fr] pt-4 gap-x-3 items-center justify-items-center">
      { trialSteps.map((trialStep: ITrialStep, idx: number) =>
        <FreeTrialStep
          key={ idx }
          { ...trialStep }
          appendSeparator={ idx < trialSteps.length - 1 }
        />
      ) }
    </div>
  );
};
