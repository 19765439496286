import SemiCircleChart from "../SemiCircleChart";

export default function RequestsCard({
  success,
  failure
}: {
  success: number;
  failure: number;
}) {
  return (
    <div className="p-8 bg-white border border-borderColor bg-white h-full flex flex-col justify-between">
      <span className="text-gray">Requests</span>
      <SemiCircleChart success={success} failure={failure} circumference={180} legend={["Success", "Failure"]} legendPosition="bottom"/>
    </div>
  );
}
