import { stepperSteps } from "./Pages";

import { ReactComponent as StepCheckmarkIcon } from "assets/icons/onboarding-stepper-checkmark.svg";

import { cx } from "utils";


function StepperStep(
  {
    step,
    isCompleted,
    isActive
  }: {
    step: string,
    isCompleted: boolean,
    isActive: boolean
  }
) {
  return (
    <div className="flex items-start gap-x-2.5">
      <div className={ cx(
        "border rounded-full p-1",
        isCompleted ? "border-brandPrimary bg-brandPrimary" : "border-gray"
      ) }>
        <StepCheckmarkIcon className="m-[1px]" />
      </div>
      <div className={ cx(isCompleted && "line-through", isActive && "font-semibold") }>
        { step }
      </div>
    </div>
  );
}

function StepperHead(
  {
    icon,
    headline,
    content
  }: {
    icon?: JSX.Element,
    headline?: string,
    content?: string | JSX.Element
  }
) {
  return (
    <div className="flex flex-col items-center gap-y-1.5 pb-16 lg:pb-0">
      { icon && <div className="pb-1.5">{ icon }</div> }
      { headline && <div className="text-lg text-center text-brandDarkest font-semibold">{ headline }</div> }
      { content && <div className="text-sm text-center text-gray">{ content }</div> }
    </div>
  );
}


export default function OnboardingStepper({ step }: { step: number }) {
  return (
    <div className="flex flex-col h-full justify-around">
      <StepperHead { ...stepperSteps[step - 1] } />
      <div className="flex flex-col gap-y-4 bg-white p-8 text-gray text-sm">
        { stepperSteps.map((stepperStep, idx) => (
          <StepperStep
            key={ idx }
            step={ stepperStep.step }
            isCompleted={ idx < step - 1 }
            isActive={ idx === step - 1 }
          />
        )) }
      </div>
    </div>
  );
};
