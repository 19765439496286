import { InputComments } from 'components/hosted-scraping/edit-project-components/InputComments';
import { NewProjectConfig, ProjectConfig, NotifyOnSuccess, NotifyOnFailure } from "providers/HostedScrapingProvider";
import { RadioGroup } from "@headlessui/react";
import { RadioGroupElement } from "components/RadioGroup";
import { SectionRightHandSide } from './SectionRightHandSide';
import { Section } from './Section';

export const NotificationPreferences = ({proj, setProj}: {proj: NewProjectConfig | ProjectConfig, setProj: (proj: NewProjectConfig | ProjectConfig) => void}) => {

  const changeSuccessNotificationType = (selectedSuccessNotificationType: NotifyOnSuccess) => setProj(
    {...proj, notificationConfig: { ...proj.notificationConfig, notifyOnSuccess: selectedSuccessNotificationType, notificationChanged: new Date() }}
  );
  const changeFailureNotificationType = (selectedFailureNotificationType: NotifyOnFailure) => setProj(
    {...proj, notificationConfig: { ...proj.notificationConfig, notifyOnFailure: selectedFailureNotificationType, notificationChanged: new Date() }}
  );

  return (
    <Section>
      <InputComments title="Notification preferences" description="Choose how you want to be notified about your completed jobs." />
      <SectionRightHandSide className="text-sm flex-col">
        <div>Notify me about run completion</div>
        <RadioGroup name='onSuccess' value={proj.notificationConfig?.notifyOnSuccess} onChange={changeSuccessNotificationType}>
          <div className="flex flex-row flex-wrap gap-4 mb-8 mt-2">
            <RadioGroupElement value="never" label="Never" />
            <RadioGroupElement value="with_every_run" label="With every run" />
            <RadioGroupElement value="daily" label="Daily" />
            <RadioGroupElement value="weekly" label="Weekly" />
          </div>
        </RadioGroup>
        <div>Notify me about failed run completion</div>
        <RadioGroup name='onFailure' value={proj.notificationConfig?.notifyOnFailure} onChange={changeFailureNotificationType}>
          <div className="flex flex-row flex-wrap gap-4 mb-8 mt-2">
            <RadioGroupElement value="with_every_run" label="With every run" />
            <RadioGroupElement value="daily" label="Daily" />
            <RadioGroupElement value="weekly" label="Weekly" />
          </div>
        </RadioGroup>
      </SectionRightHandSide>
    </Section>
  );
}
