import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FetcherWithComponents, useFetcher } from "react-router-dom";

import Button from "components/Button";
import FormWithValidation from "components/FormWithValidation";
import SubmitButton from "components/SubmitButton";
import Toaster, { ToasterId } from "components/Toaster";
import { IOnboardingQuestion } from "components/onboarding/OnboardingPage/Pages";
import QuestionsPage from "components/onboarding/OnboardingPage/QuestionsPage";

import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";

import { ErrorMessage } from "./ErrorMessage";
import { ModalButtonsSection, ModalContent, ModalSections } from "./index";

import { fmtDate, fmtNumber } from "utils";


export function CancelSubscriptionModalContent(
  {
    fetcher,
    closeModalCallback,
  }: {
    fetcher: FetcherWithComponents<any>,
    closeModalCallback: () => void,
  }
) {
  const user = useUser();

  return (
    <fetcher.Form method="POST" action="/billing-data/subscription/cancel">
      <ModalContent>
        <ModalSections>
          <div>Are you sure you want to cancel your subscription?</div>
          <div>
            Your subscription will remain with your current plan until the end
            of the billing period.{ " " }
            { user?.isBannedFromFree && (
              <>After that, your subscription will be cancelled and your API key will be removed.</>
            ) }
            { !user?.isBannedFromFree && (
              <>After that, you will be downgraded to the{ " " }
                <span className="font-bold">FREE</span> plan, with{ " " }
                <span className="font-bold">{ fmtNumber(1000) } API credits per month</span>.
              </>
            ) }
          </div>
          <ErrorMessage errorMessage={ fetcher.data?.error?.message } closeModalCallback={ closeModalCallback }/>
        </ModalSections>
        <ModalButtonsSection>
          <Button
            text="Keep Subscription"
            theme="default"
            onClick={ closeModalCallback }
            size="MD"
          />
          <SubmitButton
            text="Cancel Subscription"
            theme="error"
            size="MD"
            iconAbsolute={ false }
            disabled={ fetcher.data?.error?.shouldNotRetry }
          />
        </ModalButtonsSection>
      </ModalContent>
    </fetcher.Form>
  );
}

export function CancellationSurveyModalContent(
  {
    closeModalCallback
  }: {
    closeModalCallback: () => void;
  }
) {
  const { subscription } = useUserProvider();

  const cancellationSurveyFetcher = useFetcher();

  const secondaryQuestions: { [ index: string ]: string } = useMemo(() => { return {
    "Lower success rate than expected": "What was the success rate you experienced and/or for what domain?",
    "Price was too high": "Do you have any other feedback?",
    "Dissatisfied with support": "Do you have any other feedback what to improve on our side?",
    "Service was difficult to use": "What would have helped you?",
    "Experienced slow response times": "Can you tell us the domain(s) you experienced slow response time?",
    "Found alternative": "Can you tell us why you moved to another solution and which alternative you chose?",
    "Documentation was unclear": "What would have helped you?",
    "Specific feature was missing": "Can you tell us which feature you were looking for?",
    "Existing feature not working as expected": "Which feature were you not satisfied with and why?",
    "Experienced frequent downtimes": "What was the success rate you experienced and/or for what domain?",
    "Other/None of the above": "Do you have any other feedback?"
  } }, []);

  const firstQuestion: IOnboardingQuestion = useMemo(() => { return {
    id: "cancellation-reason",
    question: "Can you let us know why you decided to cancel?",
    type: "single-select",
    mandatory: true,
    answers: Object.keys(secondaryQuestions)
  } }, [ secondaryQuestions ]);

  const [ questions, setQuestions ] = useState([ firstQuestion ]);

  const onAnswerCallback = useCallback((event: ChangeEvent<HTMLFormElement>) => {
    if ((event.target.type === "radio") && event.target.value) {
      setQuestions([
        firstQuestion,
        {
          id: "cancellation-feedback",
          question: secondaryQuestions[event.target.value],
          type: "text-input",
          mandatory: false,
          answers: [ "" ]
        }
      ])
    }
  }, [ firstQuestion, secondaryQuestions ]);

  const [ answersSavedToastId, setAnswersSavedToastId ] = useState<ToasterId | undefined>();

  useEffect(() => {
    if (cancellationSurveyFetcher.data?.ok === "OK") {
      closeModalCallback();
      if (!answersSavedToastId) {
        setAnswersSavedToastId(
          Toaster.success("Subscription cancelled and answers sent!", "We are sorry to see you go. Thank you for participating in our survey.")
        );
      }
    }
  }, [ cancellationSurveyFetcher.data, closeModalCallback, answersSavedToastId, setAnswersSavedToastId ]);


  return (
    <>
      <ModalContent>
        <div className="flex flex-col text-gray">
          <div>
            <p>We're sorry to see you go!</p>
            <p>Your subscription was cancelled and will end on { fmtDate(new Date((subscription?.current_term_end || 0) * 1000)) }.</p>
          </div>

          <FormWithValidation
            method="POST"
            action="/billing-data/subscription/cancellation-survey"
            onChange={ onAnswerCallback }
            fetcher={ cancellationSurveyFetcher }
          >
            <QuestionsPage page={{ questions: questions }} questionsClassName="font-semibold" showQuestionsHint={ false } />

            <div className="flex flex-row justify-end pt-8">
              <SubmitButton
                text="send"
                checkFormValidity
                disabled={ cancellationSurveyFetcher.state !== "idle" || cancellationSurveyFetcher.data?.ok === "OK" }
                iconAbsolute={ false }
              />
            </div>
          </FormWithValidation>

        </div>
      </ModalContent>
    </>
  );
}
