import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useFormContext } from "react-hook-form";

const ReCaptchaField = ({ setElm }: any) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="reCaptchaToken"
      defaultValue={null}
      control={control}
      rules={{ required: "ReCaptcha is required" }}
      render={({ field }) => <Render field={field} setElm={setElm} />}
    />
  );
};

//@ts-ignore
const Render = ({ field: { name, onChange }, setElm }) => {
  const { setError } = useFormContext();
  const elm = useRef(null);

  useEffect(() => {
    if (!setElm) return;
    setElm(elm);
  }, [elm, setElm]);

  useEffect(() => {
    const elmCaptcha = elm.current;
    return () => {
      if (elmCaptcha) {
        // @ts-ignore
        elmCaptcha?.reset();
      }
    };
  }, []);

  return (
    <ReCAPTCHA
      ref={elm}
      sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
      onError={() => {
        setError(name, {
          type: "custom",
          message:
            "There was an error with ReCaptcha. Please refresh the page and try again"
        });
      }}
      onExpired={() => {
        setError(name, {
          type: "custom",
          message:
            "Your ReCaptcha Token expired, please try again or refresh the page."
        });
      }}
      onChange={onChange}
    />
  );
};

export default ReCaptchaField;
