import { useCallback, useState } from "react";

import scraperApi from "api";
import AuthForm from "components/AuthForm";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";


export default function ForgotPassword() {
  const [ resetEmailSentTo, setResetEmailSentTo ] = useState<string | null>(null);

  const submitCallback = useCallback(
    async (email: string) => {
      await scraperApi.auth.forgotPassword(email);
      setResetEmailSentTo(email);
    },
    [
      setResetEmailSentTo
    ]
  );

  const goBackLink =
    <AuthPageLinks>
      <AuthPageLink text="" link={{ to: "/login", text: "Go Back" }}/>
    </AuthPageLinks>;

  if (resetEmailSentTo) {
    return (
      <AuthPage
        title="Forgot Password"
        heading={[ "Check your inbox", `An email has been sent to ${resetEmailSentTo} with a link to reset your password` ]}
      >

        {goBackLink}

      </AuthPage>
    );
  }

  return (
    <AuthPage
      title="Forgot Password"
      heading={[ "Reset password", "Enter your email below to receive password reset instructions." ]}
    >
      <AuthForm
        askForPassword={false}
        emailButtonText="Reset password"
        emailCallback={submitCallback}
        defaultErrorMessage="Something went wrong"
      />

      {goBackLink}

    </AuthPage>
  );
};
