import _ from "lodash";

import { UserType } from "providers/UserProvider";


export const getUsername = (user: UserType): string => {
  return user ?
    ((user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : _.capitalize(user.email.split("@")[0])) :
    "";
}

export const getUserInitials = (user?: UserType | null, username?: string): string => {
  return user ? getUsername(user)[0] : (username ? username[0] : "");
}
